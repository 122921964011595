@import "colors";

.ng-select.ng-select-opened>.ng-select-container {
  background-color: var(--spai-body-bg);
  color: white;
}

.ng-select {
  .ng-select-container {
    background-color: var(--spai-dropdown-bg);
  }

  &.ng-select-focused {
    .ng-select-container {
      background-color: var(--spai-dropdown-bg);
    }
  }

  &.ng-form-control {
    &.is-valid .ng-select-container {
      border-color: #0acf97;
      padding-right: calc(1.5em + 0.75rem);
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%230acf97' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
      background-repeat: no-repeat;
      background-position: right calc(0.375em + 0.1875rem) center;
      background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
    }

    &.is-disabled .ng-select-container {
      background-color: lighten(#25283D, 5%);
      border: var(--spai-border);
      color: var(--spai-input-color);
    }

    .ng-select-container {
      background-color: var(--spai-input-bg);
    }

    &.ng-select-focused {
      .ng-select-container {
        transition: 200ms;
        background-color: var(--spai-body-bg);
        border-color: var(--spai-border-color);
        box-shadow: 0 0 0 0.25rem rgb(92 107 192 / 25%);
      }
    }
  }

  .ng-spinner-loader {
    border-left-color: $dirty-white !important;
  }

  &.ng-select-disabled {
    pointer-events: none;

    .ng-arrow-wrapper {
      display: none;
    }
  }

  &.ng-select-searchable {
    .ng-input {
      input {
        color: $white;
      }
    }
  }

  &.ng-select-single {
    .ng-select-container .ng-value-container .ng-input {
      top: 50%;
      transform: translateY(-50%);

      >input {
        color: $white;
      }
    }
  }

  &.ng-touched {
    &.ng-invalid {
      .ng-select-container {
        border: 1px solid $error-red;
      }
    }
  }

  &.enum-picker {
    &.dark {
      .ng-dropdown-panel {
        width: calc(100% + 6px) !important;

        .scroll-host {
          z-index: 1000 !important;
        }
      }

      .ng-select-container .ng-value-container .ng-value {
        .ng-value-icon.left {
          display: none;
        }

        .ng-value-label {
          color: $white;
          margin-right: 6px;
        }
      }

      .ng-select-container {
        width: calc(100% + 6px) !important;
        max-width: 100%;

        .ng-value-container {
          font-size: 1rem;
          align-items: center;
          padding: 5px;
          max-width: 100%;

          .ng-value {
            display: flex;
            align-items: center;
            border-radius: 16px;
            max-width: 100%;
            position: relative;
            padding: 3px 0;
            color: #ffffff;
            background: transparent !important;

            .ng-value-label {
              padding-right: 0;
              flex: 1 1 auto;
              margin: 0;
              max-width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }

            .ng-value-icon {
              color: #fff;
              border-radius: 50%;
              display: flex;
              position: absolute;
              right: 5px;
              height: 17px;
              align-items: center;
              justify-content: center;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }
      }

      .custom-checkbox {
        display: flex;
        font-size: var(--spai-button-font-size);
        margin-bottom: 0;
      }
    }
  }

  &.deal-type-select {
    &.costs-form-control {
      .ng-select-container {
        background: $card-background;
      }
    }
  }

  &.metric-select,
  &.pain-type-select,
  &.explainer-select {
    .ng-select-container .ng-value-container .ng-input {
      width: 70% !important;
    }

    .ng-dropdown-panel-items .ng-option {
      display: flex;
      justify-content: space-between;

      .control-button {
        opacity: 0.5;
        transition: 0.3s;

        &:hover {
          opacity: 1;
        }
      }
    }

    .ng-select-item {
      background-color: transparent;
      border: none;
      flex: 1;
      flex-direction: row;
      overflow: hidden;
      padding: 0;

      .ng-select-item-title {
        margin-bottom: 0.5rem;
        font-weight: normal;
      }

      .ng-select-item-body {
        padding: 0;
        flex: 1;
        overflow: hidden;
        background: transparent;

        .text-muted {
          color: var(--spai-body-color) !important;
        }
      }
    }

    .ng-value {
      flex: 1;
      justify-content: space-between;
      display: flex;
    }
  }

  &.explainer-select {
    .ng-value {
      flex: 0;
    }

    .card-body,
    .checkbox-label {
      display: flex;
      align-items: center;
    }

    .custom-checkbox {
      margin-bottom: 0;
      font-size: 1rem;
    }
  }

  &.dark {
    &.ng-select-multiple {
      .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
        border-right: 1px solid var(--spai-border-color);
      }

      &.explainer-select {
        .ng-select-container .ng-value-container .ng-value {
          flex-direction: row-reverse;
          border-radius: 8px;
          padding: 5px;

          .ng-value-icon.left {
            background: transparent;
            border-right: none;

            &:hover {
              background: transparent;
            }
          }
        }
      }

      .ng-select-container .ng-value-container .ng-value {
        background-color: var(--spai-primary-color);

        .ng-value-icon.left {
          color: rgba(255, 255, 255, .87);

          &:hover {
            transition: 0.3s;
            opacity: .8;
          }
        }

        .ng-value-label {
          color: $white;
        }
      }
    }

    .ng-select-container {
      font-size: 14px;
      color: rgba(255, 255, 255, 0.8);

      //background-color: map-get($colors, input-backgound);
      border: 1px solid var(--spai-border-color);
      border-radius: var(--spai-border-radius);
      transition: 0.3s ease-in;

      &:hover {
        color: $white;
        transition: 0.3s ease-in;
      }

      &:focus {
        color: $white;
        background-color: $dark-violet;
        border-color: $dark-violet;
        box-shadow: none;
      }
    }

    .ng-dropdown-panel {
      border: var(--spai-border);

      .ng-dropdown-footer,
      .ng-dropdown-header {
        border-top: 1px solid $violet-border;
        padding: 8px 10px;
        color: $white;
        background-color: $dark-violet;

        .custom-checkbox {
          padding-left: 0;

          .checkbox-label {
            padding-left: 35px;
          }
        }
      }

      .ng-dropdown-header {
        border-bottom: 1px solid $violet-border;
      }

      &.ng-select-bottom {
        border: none;

        .ng-dropdown-panel-items .ng-option:last-child {
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
        }

        .ng-dropdown-footer {
          padding: 0;

          .add-modal {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 8px 10px;
            transition: 0.3s;
            text-align: left;

            &:hover {
              cursor: pointer;
              background-color: $violet4;
            }

            span {
              position: relative;
              top: 1px;

              margin-left: 10px;
            }
          }
        }
      }

      .ng-dropdown-panel-items .ng-option {
        color: $white;
        background-color: $dark-violet;

        &.ng-option-selected {
          .ng-option.label {
            font-weight: bold;
            color: $dialog-bg;

            .custom-checkbox {
              padding-left: 0;

              .checkbox-label {
                padding-left: 35px;
              }
            }
          }
        }

        &.ng-option,
        &.ng-option-selected,
        &.ng-option-selected.ng-option-marked {
          color: $white;
          background-color: var(--spai-card-bg);

          &:hover {
            background-color: var(--spai-dropdown-item-hover-bg);
            transition: 0.3s ease-in;
          }

          &.ng-option-disabled {
            pointer-events: none;
            background-color: $dark-violet;
            color: rgba($white, 0.5);
            opacity: 0.8;
          }

          .custom-checkbox {
            padding-left: 0;

            .checkbox-label {
              padding-left: 35px;

              &.flex-between {
                justify-content: space-between;
                display: flex;
              }
            }
          }
        }
      }
    }

    .ng-dropdown-panel.ng-select-bottom {
      //border: var(--spai-border);
      border: 1px solid var(--spai-border-color);
    }

    &.ng-select-single .ng-select-container {
      min-height: 39px;
      gap: 3px;
    }
  }

  &.range-picker {
    &.harvey-balls-select {
      .ng-value-container {
        min-height: 35px !important;
        display: flex;
        align-items: center;
      }
    }

    .ng-value-container {
      justify-content: center;
    }

    .ng-option {
      text-align: center;
    }
  }

  .pipeline-overview-navigation {
    &.dark {
      .select-filter {
        .ng-select {
          &.ng-select-single .ng-select-container {
            min-height: 36px;
          }
        }
      }
    }
  }

  .status {
    display: inline-flex;

    background: $gray;
    width: 10px;
    height: 10px;
    margin: 0 5px;
    border-radius: 50%;
  }

  &.opp-search-select {
    .ng-select-container {
      color: $opp-select-option;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      background: transparent;
      border: none;

      .ng-value-container {
        padding-left: 35px;

        .ng-input {
          padding-left: 35px;
        }
      }
    }

    .ng-dropdown-panel {
      margin-top: 5px;
      border: none;
      background: transparent;
    }

    .ng-dropdown-panel-items {
      background-color: $card-background;
      border: 1px solid $violet4;
      border-radius: $border-radius;

      .ng-option {
        color: $opp-select-option;
        background-color: $card-background;
        text-align: left;

        &:hover {
          background-color: $violet4;
          transition: 0.3s ease-in;
        }
      }
    }

    .ng-arrow-wrapper .ng-arrow {
      border: none;
    }

    .ng-dropdown-header {
      background-color: $card-background;
      border: 1px solid $violet4;
      border-bottom: none;
      border-radius: $border-radius;
      text-align: left;
      padding: 15px 10px;
    }
  }
}


.ng-select-item {
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}

.ng-select .ng-select-item-subtitle {
  font-size: 14px;
  margin-bottom: 0;
  color: #cccccc !important;
}

.question {
  .ng-select {
    &.ng-select-single {
      .ng-select-container {
        .ng-value-container {
          justify-content: flex-end;
          padding-right: 7px;
        }
      }
    }

    .ng-dropdown-panel {
      .ng-dropdown-panel-items {
        .ng-option {
          text-align: right;
        }
      }
    }
  }
}

.line-height-1 {
  line-height: 1!important;
}
.ng-dropdown-panel.metric-select {
  border: var(--spai-border) !important;

  .spai-button-icon {
    line-height: 1;
  }
  &.dark {
    .ng-dropdown-panel-items .ng-option.ng-option {
      color: $white;
      background-color: var(--spai-card-bg);

      &:hover {
        background-color: var(--spai-dropdown-item-hover-bg);
        transition: 0.3s ease-in;
      }

      &.ng-option-disabled {
        pointer-events: none;
        background-color: $dark-violet;
        color: rgba($white, 0.5);
        opacity: 0.8;
      }
    }

    .ng-select-item {
      background-color: transparent;
      border: none;
      flex: 1;
      flex-direction: row;
      overflow: hidden;
      padding: 0;

      .ng-select-item-title {
        margin-bottom: 0.5rem;
        font-weight: normal;
      }

      .ng-select-item-body {
        padding: 0;
        flex: 1;
        overflow: hidden;
        background: transparent;

        .text-muted {
          color: var(--spai-body-color) !important;
        }
      }
    }

    .ng-value {
      flex: 1;
      justify-content: space-between;
      display: flex;
    }
  }

  .ng-select-item {
    background-color: transparent;
    border: none;
    flex: 1;
    flex-direction: row;
    overflow: hidden;
    padding: 0;
  }

  .ng-select-item-subtitle {
    font-size: 14px;
    margin-bottom: 0;
    color: #cccccc !important;
  }

  .ng-dropdown-footer,
  .ng-dropdown-header {
    border-top: 1px solid #454a66;
    padding: 8px 10px;
    color: #fff;
    background-color: #141625;
  }
}

.accordion {
  .card {
    overflow: visible;
  }
}

#status-picker {
  .ng-value-container {
    display: flex;
    align-items: center;
    padding: 6px 12px;

    .ng-placeholder {
      padding: 0;
    }

    .ng-input {
      display: none;
    }
  }

  .ng-dropdown-header {
    border-bottom: 1px solid $violet4;
  }
}

.ng-select .ng-clear-wrapper .ng-clear {
  line-height: normal !important;
}

.ng-select .ng-clear-wrapper {
  width: auto !important;
}
