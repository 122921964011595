.nav-tabs {
  --#{$prefix}nav-tabs-link-active-bg: var(--spai-primary-color);
  --#{$prefix}nav-tabs-link-active-color: white;
  --#{$prefix}nav-tabs-border-color: var(--spai-primary-color);
  --#{$prefix}nav-tabs-link-active-border-color: var(--spai-primary-color);
  --spai-nav-tabs-border-width: 1px;
  border-bottom: var(--spai-nav-tabs-border-width) solid var(--#{$prefix}nav-tabs-border-color);
}

.nav-link:hover {
  color: white;
  border-color: var(--spai-primary-color);
}
