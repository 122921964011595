button {
  background-color: transparent;
}

.spai-button {
  &.spai-loading {
    background-image: linear-gradient(to right, #49507b 8%, #5c6bc0 18%, #49507b 33%);
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    opacity: 0.7;
    cursor: wait;
  }

  &.loading {
    cursor: wait;
    pointer-events: none;
    opacity: 0.7;
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background-color: #2b3048;
    background-image: linear-gradient(to right, #49507b 8%, #49507b 18%, #49507b 33%) !important;
    background-size: 800px 104px;
    position: relative;
    size: 100px 18px;
  }

  &.spai-button-icon {
    width: auto;
    height: auto;
    line-height: 1;
  }

  &.dropdown:after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: middle;
    content: '';
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
  }

  &-icon {
    line-height: 1;
    height: auto;
    width: auto;
    height: auto;
    line-height: 1;
  }

  &-outline {
    &.spai-button-icon {
      border: 1px solid var(--spai-primary);
    }
  }

  &:disabled,
  &[disabled] {
    opacity: 0.4;
    cursor: not-allowed;
    pointer-events: none;
  }

  &-link {
    line-height: 1;

    &:hover {
      color: var(--spai-link-hover-color) !important;
    }
  }

  &.mat-menu-item {
    border-radius: 0;
  }
}

.spai-button-group {
  position: relative;
  display: inline-flex;
  vertical-align: middle;

  &.spai-button-group-sm {
    --spai-body-font-size: 0.875rem;

    .spai-button {
      --spai-btn-line-height: 2rem;
      --spai-btn-width: 1.9rem;
    }
  }

  .spai-button {
    margin-left: -1px;
    font-size: var(--spai-body-font-size);

    &-icon {
      line-height: var(--spai-btn-line-height);
      height: var(--spai-btn-line-height);
      width: var(--spai-btn-width);
    }

    &:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .spai-button {
    &:nth-child(n + 3) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.spai-column-button {
  height: 2rem;
}

.mat-mdc-button-ripple {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  pointer-events: none;
  border-radius: inherit;
}
