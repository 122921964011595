:root {
  --spai-border-radius: 4px;
  --spai-open-nav-side-width: 117px;
  --spai-gap: 16px;
  --spai-border: 1px solid var(--spai-border-color);
  --spai-primary-collor: #5C6BC0;
  --spai-primary: #5C6BC0;
  --spai-dropdown-item-padding-x: 1rem;
  --spai-dropdown-item-padding-y: .375rem;
  --spai-placeholder-color: #999999;

  --spai-primary-color: var(--spai-primary);

  --#{$prefix}success-rgb: 60, 195, 119;
  --#{$prefix}warning-rgb: 255, 193, 7;
  --#{$prefix}danger-rgb: 220, 53, 69;
  --spai-panel-color: #25283d;
  --spai-label-color: #aab8c5;
  --spai-border-color: #464f5b;
  --spai-dropdown-bg: #2b2e47;

  --spai-link-hover-color: #ffbc00;
  --spai-link-color: #a6aedc;

  //
  --#{$prefix}body-bg: var(--spai-body-bg);
  --spai-dropdown-item-hover-bg: var(--spai-primary-color);

  //popover
  --spai-popover-arrow-outlet: var(--spai-border-color);
  --spai-popover-border: 1px solid var(--spai-border-color);
  --spai-popover-bg-header: var(--spai-primary-color);

  // input
  --spai-input-bg: #25283D;
  --#{$prefix}input-bg: var(--spai-input-bg);

  // nav
  --spai-nav-bg: #{map-get($spotlight-dark-colors, card-bg)};


}

body {
  background-color: var(--spai-body-bg);
}

:root {
  .avatar {
    border: initial none initial;
    border-image-source: initial;
    border-image-slice: initial;
    border-image-repeat: initial;

    &:hover {
      background-color: transparent;
    }

    &.user-menu {
      &:after {
        display: inline-block;
        margin-left: 0.255em;
        vertical-align: 0.255em;
        content: "";
        border-top: 0.3em solid;
        border-right: 0.3em solid transparent;
        border-bottom: 0;
        border-left: 0.3em solid transparent;
      }
    }
  }

  .picker-toggler {
    &:after {
      display: inline-block;
      margin-left: 0.255em;
      vertical-align: 0.255em;
      content: "";
      border-top: 0.3em solid;
      border-right: 0.3em solid transparent;
      border-bottom: 0;
      border-left: 0.3em solid transparent;
    }
  }
}
