.mat-slider.mat-accent .mat-slider-track-fill,
.mat-slider.mat-accent .mat-slider-thumb,
.mat-slider.mat-accent .mat-slider-thumb-label,
.mat-slider-thumb-label-text,
.mat-slider-thumb-label,
.mat-slider-track-background
 {
  background-color: var(--spai-primary-color) !important;
}

.mat-slider.mat-accent .mat-slider-thumb-label-text {
  color: white;
}
