.spai-mat-checkbox {

  --mdc-checkbox-unselected-icon-color: #A9C7FF;
  --mdc-checkbox-unselected-hover-icon-color: #a7c6fe;
  --mdc-checkbox-selected-icon-color: transparent;
  --mdc-checkbox-selected-hover-icon-color: transparent;

  $width: 18px !default;
  $height: 18px !default;
  line-height: 1;

  &-circle {
    .mdc-checkbox__background {
      border-radius: 50%;
    }
  }

  &-no-padding {
    --mdc-checkbox-state-layer-size: 0;

    .mdc-checkbox {
      padding: 0 !important;
    }
  }

  &-md {
    $width: 20px;
    $height: 20px;

    .mdc-checkbox {
      flex: 0 0 $width;
      width: $width;
      height: $height;

      .mdc-checkbox__background {
        width: $width;
        height: $height;
        border-width: 1px;

        .mdc-checkbox__checkmark {
          padding: 3px;
        }
      }
    }
  }

  &-large {
    $width: 24px;
    $height: 24px;

    .mdc-checkbox {
      flex: 0 0 $width;
      width: $width;
      height: $height;

      .mdc-checkbox__background {
        width: $width;
        height: $height;
        border-width: 1.5px;

        .mdc-checkbox__checkmark {
          padding: 4px;
        }
      }
    }
  }

  &-sm {
    $width: 16px;
    $height: 16px;

    .mdc-checkbox {
      flex: 0 0 $width;
      width: $width;
      height: $height;

      .mdc-checkbox__background {
        width: $width;
        height: $height;
        border-width: 1px;

        .mdc-checkbox__checkmark {
          padding: 2px;
        }
      }
    }
  }

  .mdc-checkbox {
    .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background {
      border-color: #a7c6fe !important;
    }

    .mdc-checkbox__background {
      top: 0;
      left: 0;
    }
  }

  .mat-checkbox-inner-container {
    width: 24px;
    height: 24px;

    .mat-checkbox-frame {
      border-radius: 50%;
      border-color: #a7c6fe;
      border-width: 1.5px;
    }

    .mat-checkbox-checkmark {
      padding: 4px;
      color: #a7c6fe;
    }
  }
}
