.cdk-drag,
.grab {
  cursor: grab !important;
}

.grabbing {
  cursor: grabbing !important;
}

.drag-handle {
  color: $violet6;
  cursor: grab;

  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.4;
  transition: 0.4s;

  &:hover {
    opacity: 1;
  }
}

.cdk-drag-preview {
  box-sizing: border-box;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
  0 8px 10px 1px rgba(0, 0, 0, 0.14),
  0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.draggable-list.cdk-drop-list-dragging .drag-box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.drag-placeholder {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
