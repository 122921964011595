body[data-theme="light"] {
    --spai-body-bg: #fafbfe;
    --spai-body-color: #6c757d;

    --spai-border-radius: 4px;
    --spai-open-nav-side-width: 117px;
    --spai-gap: 16px;
    --spai-dropdown-item-padding-x: 1rem;
    --spai-dropdown-item-padding-y: .375rem;
    --spai-border: 1px solid var(--spai-border-color);

    --spai-primary-color: #5c6bc0;
    --spai-panel-color: #25283d;
    --spai-label-color: #aab8c5;

    --spai-border-color: #464f5b;
    --spai-dropdown-bg: #2b2e47;

    --spai-dropdown-item-hover-bg: #5C6BC0;
    --spai-dropdown-item-color: #aab8c5;

    --spai-radial-gradient: radial-gradient(circle, var(--spai-body-color) 0%,var(--spai-primary-color) 100%);

  //popover
    --spai-popover-bg: var(--spai-panel-color);
    --spai-popover-arrow-outlet: var(--spai-border-color);
    --spai-popover-border: 1px solid var(--spai-border-color);
    --spai-popover-bg-header: var(--spai-primary-color);
}
