.popover {
  --#{$prefix}popover-bg: var(--#{$prefix}card-bg); // ignored
  --#{$prefix}popover-body-color: #ffffff;
  --#{$prefix}popover-border-color: var(--#{$prefix}border-color);

  --#{$prefix}popover-bg: var(--#{$prefix}panel-color);
  --#{$prefix}popover-arrow-outlet: var(--#{$prefix}border-color);
  --#{$prefix}popover-border: 1px solid var(--#{$prefix}border-color);
  --#{$prefix}popover-bg-header: var(--#{$prefix}primary-color);
}

.popover-header {
  --#{$prefix}popover-inner-border-radius: var(--#{$prefix}border-radius);
  --#{$prefix}popover-inner-border-radius: var(--#{$prefix}border-radius);
  --#{$prefix}popover-header-bg: var(--#{$prefix}primary);

  border-top-left-radius: var(--#{$prefix}border-radius) !important;
  border-top-right-radius: var(--#{$prefix}border-radius) !important;
}
