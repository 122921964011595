@import "colors";

.filters-picker-wrapper {
  .mat-list-item-content {
    &:hover {
      background: $violet3;
    }
  }

  .mat-list-base {
    padding: 11px 0;
    border: var(--spai-border);

    .mat-list-text {
      padding: 0 !important;
    }
  }

  .mat-list-base .mat-list-item .mat-list-item-content-reverse,
  .mat-list-base .mat-list-option .mat-list-item-content-reverse {
    padding: 0 10px;

    &:hover {
      .icon {
        opacity: 1;
      }
    }
  }

  .mat-list-base .mat-list-item,
  .mat-list-base .mat-list-option {
    height: 31px;
  }
}
