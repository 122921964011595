$prefix: spai- !default;
$icon-size: 24px;


@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

@import '~@ng-select/ng-select/themes/default.theme.css'; // Should this really load here instead of /vendor (putting it before everything)?
@import './styles/main.scss';
@import '@spotlight/shared';
// @import '~quill/dist/quill.bubble.css';
// or
@import '~quill/dist/quill.snow.css';
// What follows should be merged into the root theme - not set here.
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: $spotlight-font-family-sans-serif;

  scrollbar-color: $scrollbar-thumb-color-hover $scrollbar-track-color;
  scrollbar-width: thin;

  line-height: 1.5rem;

  &::-webkit-scrollbar {
    width: 8px;
    height: 5px;
    border-radius: 25px;
  }

  &::-webkit-scrollbar-track {
    background: $scrollbar-track-color;
  }

  &::-webkit-scrollbar-thumb {
    background: $scrollbar-thumb-color;
    border-radius: 25px;
    transition: 0.5s ease-in;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: $scrollbar-thumb-color-hover;
    transition: 0.5s ease-in;
  }

  &:focus {
    outline: none;
  }
}

// heatmap tooltip
body {
  scrollbar-width: none;
}

.animate {
  transition: all 1s ease-in-out;
}

.custom-popup.bs-popover-top > .arrow::after,
.custom-popup.bs-popover-auto[x-placement^='top'] > .arrow::after {
  border-top-color: $violet3;
}

.custom-popup {
  min-width: 30px;
  background-color: $violet3;

  .popover-body {
    color: $white;
  }
}

.custom-modalbox {
  mat-dialog-container {
    background-color: #454e80;
  }
}

.box-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.body-holder {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.error-text {
  visibility: hidden;
  opacity: 0;
  right: 0;

  font-size: 14px;
  position: absolute;
  bottom: -25px;
  color: $error-red;
  transition: 0.3s;
}

ngb-datepicker-month {
  background-color: #ffffff;
}

.metric {
  .mt-2 {
    &.tab-content {
      width: 100%;
    }
  }
}

////////////////////////////////////
/////////// Scrollbar //////////////
////////////////////////////////////
// ::-webkit-scrollbar {
//   width: 8px;
//   height: 5px;
// }

// /* Track */
// ::-webkit-scrollbar-track {
//   background: $dark-violet2;
// }

// /* Handle */
// ::-webkit-scrollbar-thumb {
//   background: $dialog-bg;
//   border-radius: 25px;
//   transition: 0.5s ease-in;
// }

// /* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//   background: $violet9;
//   transition: 0.5s ease-in;
// }

.mask {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background-color: #2b3048;
  background-image: linear-gradient(to right, #2b3048 8%, #49507b 18%, #2b3048 33%);
  background-size: 800px 104px;
  position: relative;
  size: 100px 18px;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }

  100% {
    background-position: 468px 0;
  }
}

.readOnly {
  opacity: 0.5;
  pointer-events: none;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.mat-dialog-container {
  overflow: visible;
}

.spai-new-opp-modal .mat-dialog-container {
  padding: 34px;
}

.heatmap-tooltip {
  position: absolute;
  z-index: 99999;
}

.explainers-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.comment {
  ul {
    margin-bottom: 0 !important;
  }
}

.cdk-overlay-container.cdk-overlay-container-enums {
  width: auto;
  height: auto;
  //after update to angular 15
 // height: calc(100% - 120px);
}
