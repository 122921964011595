@import "colors";

$colors: (
  body: #282a3e,
  header: #282a3e,
  card: #111422,
  card-title: #FDFBFF,
  input-backgound: #2c3045,
  menu-background: #485082,
  menu-text: #E3E2E6,
);


$breakpoints: (
  xs: 769px,
  sm: 904px,
  md: 1240px,
  lg: 1366px,
  xl: 1440px,
);

$breakpointsMax: (
  xs: map-get($breakpoints, xs)- 1,
  sm: map-get($breakpoints, sm) - 1,
  md: map-get($breakpoints, md) - 1,
  lg: map-get($breakpoints, lx)- 1,
  xl: map-get($breakpoints, xl)- 1,
);

$bp-max-xs: "max-width:" map-get($breakpoints, xs);

$bp-screen-max-xs: "only screen and (max-width: #{map-get($breakpointsMax, xs)})";
$bp-screen-min-xs: "only screen and (min-width: #{map-get($breakpointsMax, xs)})";
$bp-screen-max-sm: "only screen and (max-width: #{map-get($breakpointsMax, sm)})";
$bp-screen-min-sm: "only screen and (min-width: #{map-get($breakpointsMax, sm)})";
$bp-screen-max-md: "only screen and (max-width: #{map-get($breakpointsMax, md)})";
$bp-screen-min-md: "only screen and (min-width: #{map-get($breakpointsMax, md)})";
$bp-screen-max-lg: "only screen and (max-width: #{map-get($breakpointsMax, lg)})";
$bp-screen-min-lg: "only screen and (min-width: #{map-get($breakpointsMax, lg)})";
$bp-screen-max-xl: "only screen and (max-width: #{map-get($breakpointsMax, xl)})";
$bp-screen-min-xl: "only screen and (min-width: #{map-get($breakpointsMax, xl)})";

$border-radius: 4px;

@mixin media($sizetype, $size) {
  $conditions: ();
  $conditions: append($conditions,
    "only screen and (#{$sizetype}: #{map-get($breakpointsMax, md)})"
  );

  @media #{$conditions} {
    @content;
  }
}
