$theme-name: dark;

body[data-theme="#{$theme-name}"] {
  --spai-body-color: #{$spai-dark-body-color};
  --spai-body-color-rgb: 255, 255, 255;
  --spai-body-bg: #{$spai-dark-body-bg};
  --spai-border-color: #{$spai-dark-border};
  --spai-input-bg: #{$spai-dark-card-bg};
  --spai-card-bg: #{$spai-dark-card-bg};
  --#{$prefix}link-color: #{lighten($primary, 20%)};
  --#{$prefix}link-hover-color: var(--#{$prefix}warning);
  --#{$prefix}body-font-weight: 200;

  --#{$prefix}body-bg: #{map-get($spotlight-dark-colors, body-bg)};
  --#{$prefix}body-color: #{map-get($spotlight-dark-colors, body-color)};
  --#{prefix}body-bg: var(--spai-body-bg);

  --#{prefix}body-color-rgb: 255, 255, 255;

  --#{$prefix}border-radius: 4px;
  --#{$prefix}open-nav-side-width: 117px;
  --#{$prefix}gap: 16px;
  --#{$prefix}dropdown-item-padding-x: 1rem;
  --#{$prefix}dropdown-item-padding-y: .375rem;
  --#{$prefix}border: 1px solid var(--spai-border-color);

  --#{$prefix}primary-color: #5c6bc0;
  --#{$prefix}panel-color: #25283d;
  --#{$prefix}label-color: #aab8c5;

  --#{$prefix}border-color: #434b55;
  --#{$prefix}dropdown-bg: #2b2e47;

  --#{$prefix}dropdown-item-hover-bg: #5C6BC0;
  --#{$prefix}dropdown-item-color: #aab8c5;

  --#{$prefix}radial-gradient: radial-gradient(circle, var(--#{$prefix}body-color) 0%, var(--#{$prefix}primary-color) 100%);
}

body[data-theme=dark] .avatar {
  color: #fff;
}
