@use 'sass:map';
@use "@angular/material" as mat;

@import './colors';

$standard-height: 36px;

// material dark theme background palette
$dark-theme-background-palette: (
  status-bar: map.get(mat.$grey-palette, 900),
  app-bar: $spai-dark-panel-bg,
  background: $spai-dark-body-bg,
  hover: $spai-primary,
  card: $spai-dark-panel-bg,
  dialog: $spai-dark-panel-bg,
  disabled-button: rgba(white, 0.12),
  raised-button: $spai-dark-panel-bg,
  focused-button: $light-focused,
  selected-button: $spai-primary,
  selected-x: map.get(mat.$grey-palette, 900),
  selected-disabled-button: $spai-dark-panel-bg,
  disabled-button-toggle: black,
  unselected-chip: $spai-primary,
  disabled-list-option: rgba(white, 0.12),
  tooltip: $spai-dark-panel-bg,
);

// Setup dark theme (Spotlight uses the standard $indigo-palette)
$dark-primary: mat.define-palette(mat.$indigo-palette, 400); // hue = $primary (Bootstrap)
$dark-accent: mat.define-palette(mat.$gray-palette, A200, A100, A400);
$dark-warn: mat.define-palette(mat.$red-palette);

$dark-theme: mat.define-dark-theme((color: (primary: $dark-primary,
        accent: $dark-accent,
        warn: $dark-warn ),
        typography: $spotlight-typography,
        ) );

$dark-theme: map-merge($dark-theme, (background: $dark-theme-background-palette,
      color: (map-merge(map-get($dark-theme, color), (background: $dark-theme-background-palette)))));

@include mat.legacy-core-theme($dark-theme); // Apply the dark theme by default

// @include mat.all-component-themes($dark-theme); // Load all components
// or just the ones we care about
// @include mat.autocomplete-theme($dark-theme);
// @include mat.button-theme($dark-theme);
@include mat.legacy-dialog-theme($dark-theme);
@include mat.dialog-theme($dark-theme);

// @include mat.select-theme($dark-theme);
@include mat.legacy-card-theme($dark-theme);
@include mat.legacy-chips-theme($dark-theme);
@include mat.chips-theme($dark-theme);
@include mat.legacy-slider-theme($dark-theme); // Adds tick marks on slider
@include mat.datepicker-theme($dark-theme); // Adds tick marks on slider
// @include mat.sort-theme($dark-theme);
// @include mat.toolbar-theme($dark-theme);
@include mat.legacy-menu-theme($dark-theme);
@include mat.menu-theme($dark-theme);
@include mat.form-field-theme($dark-theme);
@include mat.button-toggle-theme($dark-theme);



:root {
  --mdc-theme-error: #{$spai-danger};
}
.material-icons {
  font-size: 1rem;
}

.mat-mdc-form-field-error,
.spai-invalid-messagge,
.invalid-feedback
{
  text-align: end;
  font-size: 0.875rem;
}

.mat-divider,
mat-divider {
  color: #434b55;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0.438rem 0;
  border-top: 5px solid transparent;
}

.spai-mat-select {
  .mat-form-field-wrapper {
    padding: 0;
  }
}

.mat-select {
  &.text-end {
    .mat-select-trigger {
      text-align: end;
      height: inherit;
      align-items: baseline;
    }
  }

  .mat-select-trigger {

    height: inherit;
    align-items: baseline;
  }
}

.mat-select-panel {
  border: var(--spai-border);
  background-color: var(--spai-card-bg);

  .mat-pseudo-checkbox {
    width: 20px;
    height: 20px;
    border: 2px solid #505888;

    &.mat-pseudo-checkbox-checked {
      background-color: #505888;
    }

    &:after {
      color: #ffffff;
      width: 13px;
      height: 5px;
    }
  }

  &.mat-primary {
    .mat-option.mat-selected:not(.mat-option-disabled) {
      color: #ffffff;
    }

    s .checkmark {
      position: absolute;
      width: 20px;
      height: 20px;
      background-color: transparent;
      border: 2px solid #505888;
      border-radius: 2px;
    }
  }
}
