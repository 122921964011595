// Really shouldn't be necessary but, oh wait... it's f-ing Google
::placeholder,
.mat-input-element,
.mat-form-field {
  font-family: unset;
}

// Keep the outline border from getting thick just because it has focus
.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-start,
.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-end,
.mat-form-field-appearance-outline .mat-form-field-outline-thick .mat-form-field-outline-gap {
  border-width: 1px !important;
}

.mat-form-field-appearance-fill .mat-form-field-ripple {
  height: 1px;
}

.mat-form-field {
  width: 100%;
}

mat-form-field {
  * {
    line-height: 1;
  }

  &.competitor-select {
    .mat-form-field-wrapper {
      padding-bottom: 0;
    }
  }

  &.spai-mat-form-field {
    font-size: 1rem;

    &.spai-competitor-mat-form-field {
      .mat-mdc-form-field-flex {
        .mat-mdc-form-field-infix {
          padding-top: 0.775rem;
          padding-bottom: 0.775rem;
        }
      }
    }

    .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover.mdc-notched-outline .mdc-notched-outline__leading,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
    .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
      border-color: var(--spai-border-color);
    }

    .mdc-text-field--outlined {
      &:not(.mdc-text-field--disabled):not(.mat-mdc-form-field-error) {
        &:not(.mdc-text-field--focused) {
          &:hover {
            .mdc-notched-outline {

              .mdc-notched-outline__trailin,
              .mdc-notched-outline__leading,
              .mdc-notched-outline__notch {
                border-color: var(--spai-border-color);
              }
            }
          }
        }
      }

      padding-left: var(--spai-input-padding-x);

      .mdc-notched-outline__notch {
        .mdc-floating-label {
          color: var(--spai-text-color) !important;
        }
      }
    }

    .mdc-text-field:not(.mdc-text-field--invalid) {
      .mdc-notched-outline {

        .mdc-notched-outline__leading,
        .mdc-notched-outline__trailing,
        .mdc-notched-outline__notch {
          border-color: var(--spai-border-color);
        }
      }
    }

    &.mat-focused {
      .mat-mdc-text-field-wrapper {
        background-color: #17171d;
      }

      .mdc-notched-outline {
        box-shadow: rgb(92 107 192 / 25%) 0 0 0 0.25rem;
        border-radius: var(--spai-border-radius);

        .mdc-notched-outline__leading,
        .mdc-notched-outline__trailing {
          border-width: 1px;
          border-color: var(--spai-border-color);
        }
      }

      .mat-form-field-outline {

        .mat-form-field-outline-start,
        .mat-form-field-outline-end,
        .mat-form-field-outline-gap {
          border-width: 1px;
          border-color: var(--spai-border-color);
        }
      }
    }

    .mat-form-field-wrapper {
      padding-bottom: 0;

      .mat-form-field-flex {
        padding: 0 0.45rem;

        .mat-form-field-outline {
          top: -1px;
          bottom: -1px;
          border-radius: var(--spai-border-radius);
        }
      }

      .mat-form-field-suffix {
        top: -0.3125rem;

        .mat-icon-button {
          width: 1rem;
          height: 1rem;
        }
      }
    }

    .mat-form-field-infix {
      border-top: 0;
    }

    .mat-form-field-prefix,
    .mat-form-field-suffix {
      top: -0.3125rem;

      .search-icon {
        display: inline-block;
      }
    }

    .mat-mdc-form-field-flex {

      .mat-form-field-infix,
      .mat-mdc-form-field-infix {
        padding-top: 0.475rem;
        padding-bottom: 0.55rem;
        min-height: 0;

        input.mat-input-element,
        input.mat-mdc-input-element {
          caret-color: #ffffff;
          line-height: 1.5;
          font-weight: 400;
          font-size: 1rem;
          margin: 0;
        }
      }
    }

    .mat-form-field-outline {

      .mat-form-field-outline-start,
      .mat-form-field-outline-end,
      .mat-form-field-outline-gap {
        border-width: 1px;
      }
    }

    .mat-mdc-form-field-icon-suffix {
      padding-right: var(--spai-input-padding-x);
    }

    .mat-mdc-form-field-error-wrapper {
      padding: 0;
    }
  }

  &.mat-form-field-appearance-outline .mat-form-field-label {
    margin-top: 0;
  }
}


.spai-competitors-selector,
.spai-pain-selector {
  .mat-mdc-form-field-infix {
    display: flex;
    gap: 1.5rem;
    overflow: hidden;

    .mat-mdc-chip-set {
      max-width: 75%;

    }
    .mdc-evolution-chip {
      max-width: none;
    }
    .mdc-evolution-chip-set__chips {
      flex-wrap: nowrap;
      overflow: auto;
    }
  }
}
