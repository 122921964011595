.spai-select {
  --spai-select-bg: var(--spai-input-bg);

  width: 100%;
  display: inline-block;
  border: var(--spai-border);
  background-color: var(--spai-select-bg);
  border-radius: var(--spai-border-radius);
  padding: .375rem .75rem;
  line-height: 1;
  font-size: 1rem;
  font-weight: 400;

  &.is-valid {
    border-color: #0acf97;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%230acf97' d='M2.3 6.73.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }

  &.spai-select-disabled {
    pointer-events: none;
    background-color: lighten(#25283DFF, 5%);
    border: var(--spai-border);
    color: var(--spai-input-color);
  }

  // style for custom select trigger
  spai-select-trigger {
    display: inline-block;
    vertical-align: middle;
    line-height: 1;
  }

  * {
    line-height: 1;
  }

  &.form-control:focus,
  &:focus {
    background-color: var(--#{$prefix}body-bg);
    color: var(--spai-input-color);
    border-color: var(--spai-border-color);
    box-shadow: rgb(92 107 192 / 25%) 0px 0px 0px 0.25rem;
  }

  &.spai-select-sm {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
    font-size: .875rem;
    border-radius: 0.25rem;

    .spai-select-trigger {
      height: 1.313rem;
      display: inline-flex;
      align-items: center;
      cursor: pointer;
      position: relative;
      box-sizing: border-box;
      width: 100%;
    }


  }

  &.form-select {
    background-image: none;
  }


  .spai-select-trigger {
    height: 1.5rem;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    box-sizing: border-box;
    width: 100%;
  }

  .spai-select-placeholder {
    color: var(--spai-placeholder-color);
    user-select: none;
  }

  .spai-select-value {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #ffffff;
  }

}


.spai-select-clear-wrapper {
  .spai-select-clear {
    display: flex;
  }
}

.spai-select-panel-wrap {
  flex-basis: 100%;
  margin-top: 4px;
  margin-bottom: 4px;

  .spai-select-panel {
    min-width: calc(100% + 24px);
    min-width: 112px;
    // max-width: 280px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding-top: 5px;
    padding-bottom: 5px;
    max-height: 256px;
    min-width: 100%;
    background: var(--spai-card-bg);
    border: 1px solid var(--spai-border-color);
    border-radius: var(--spai-border-radius);
    outline: 0;
  }

}


.spai-select-arrow-wrapper {
  height: 16px;
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;

  .spai-select-arrow {
    width: 0;
    height: 0;
    border-left: 5px solid rgba(255, 255, 255, 0);
    border-right: 5px solid rgba(255, 255, 255, 0);
    border-top: 5px solid #ffffff;
    margin: 0 4px;
  }
}


.spai-option {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  padding: 0.25rem 1rem;
  height: auto;
  line-height: 1.5rem;

  &:hover {
    &:not([disabled]) {
      background-color: var(--spai-dropdown-item-hover-bg);
    }
  }
}


.spai-mat-select {
  .mat-form-field-appearance-outline .mat-select-arrow-wrapper {
    transform: translateY(0%);
  }
}
