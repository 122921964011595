ol.spai-admin-nav-list,
ul.spai-admin-nav-list {
  list-style: none;
  counter-reset: index;

  &.spai-admin-nav-list--main {
    padding-left: 0;
  }
}

li.spai-admin-nav-list-item {
  padding: 12px 0;
  padding-left: 8px;
  border-radius: var(--spai-border-radius);
  // &::before {
  //   font-size: 18px;
  //   font-weight: 800;
  //   content: counters(index, '.', decimal);
  //   counter-increment: index;
  //   border: 2px solid #7b7f96;
  //   border-radius: 50%;
  //   width: 40px;
  //   height: 40px;
  //   display: inline-flex;
  //   align-items: center;
  //   justify-content: center;
  // }

  &:not(:last-child) {
    // margin-bottom: 20px;
  }

  &:hover {
    background-color: rgba($color: #5C6BC0, $alpha: 0.75) ;


    &::before {
      color: #3775ff;
      border-color: #3775ff;
    }
  }

  &.active {
    background-color: var(--spai-primary);
  }
}

.spai-list-item-wrapper {
  // margin-left: 15px;
  display: inline-flex;
  // width: calc(100% - 55px);
  width: 100%;
  justify-content: space-between;
  align-items: baseline;
}

.spai-ghost {
  display: flex;
  align-items: center;
  gap: 1rem;

  &-circle {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation-duration: 1rs;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background-color: #2b3048;
    background-image: linear-gradient(to right,
        #2b3048 8%,
        #49507b 18%,
        #2b3048 33%);
    position: relative;
    background-size: 800px 104px;

  }

  &-line {
    display: block;
    height: 24px;
    width: 100px;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background-color: #2b3048;
    background-image: linear-gradient(to right,
        #2b3048 8%,
        #49507b 18%,
        #2b3048 33%);
    position: relative;
    background-size: 800px 104px;
  }
}
