body[data-theme="dark"] {
    --spai-table-bg: transparent;
    --spai-table-border-color: #434b55;
    --spai-table-header-color: #42476d;
    --spai-table-striped-bg: rgb(47, 50, 77);
    --spai-table-row-color: #bfbfbf;
    --spai-table-accent-bg: rgb(47, 50, 77);

    --spai-table-header-bg: #42486d;
    --#{$prefix}table-striped-color: var(--spai-table-row-color);
}


.table {
    --#{$prefix}table-striped-color: var(--spai-table-row-color);

    td {
        color: var(--spai-table-row-color);
    }

    thead {
        tr {
            background-color: var(--spai-table-header-bg);

            th {
                color: white;
            }
        }
    }
}
