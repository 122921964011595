@import '_variables';

body {
  font-family: $spotlight-font-family-sans-serif;
  color: var(--spai-body-color);
  background-color: var(--spai-body-bg);
}

a {
  padding-left: 0;
  padding-right: 0;
  text-decoration: none;
  color: var(--spai-link-color);

  &:hover {
    color: var(--spai-link-hover-color);
  }
}

.disabled,
.spia-disabled {
  pointer-events: none;
  opacity: .5;
}


.spai-main-content {
  background-color: var(--spai-body-bg);
}

.spai-page-content {
  background-color: var(--spai-body-bg);
}

.spai-page-title,
h1.spai-page-title,
.page-title {
  margin-top: 0;
  font-size: 1.5rem;
  font-weight: 700;

  .page-title--secondary,
  .spai-page-title--secondary {
    font-weight: 300;
  }
}

.spai-administration-card.mat-card {
  display: flex;
  flex-direction: column;

  mat-card-content {
    flex-grow: 1;
  }
}

a,
button {
  spai-icon:not(:last-child) {
    margin-right: 0.5rem;
  }
}

button.dropdown-toggle::after {
  content: none;
}

.spai-icon {
  .spai-icon-svg {
    &.md {
      width: 1.5rem;
      height: 1.5rem;
    }

    &.sm {
      width: 1rem;
      height: 1rem;
    }
  }
}

@each $name,
$color in $spai-score-collor {
  .spai-text-#{$name} {
    color: $color;
    fill: $color;

    svg {
      fill: $color;
    }
  }
}

.spai-opportunity-button-block {
  position: sticky;
  bottom: 0;
  z-index: 9999;
  border-bottom: 24px solid var(--spai-body-bg);
  border-right: none;
  border-left: none;
  border-radius: 0;
  background-color: var(--spai-body-bg);

  .spai-opportunity-bottom-container {
    border-radius: 0;
    border-top: 16px solid var(--spai-body-bg);
  }
}

.mat-drawer-transition .mat-drawer-backdrop {
  transition-duration: 400ms;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-property: background-color, visibility;
}

.mat-drawer-backdrop.mat-drawer-shown {
  visibility: visible;
}

.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6);
}

.mat-drawer-backdrop {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  display: block;
  z-index: 3;
  visibility: hidden;
}

legend {
  float: unset;
  font-size: 1rem;
  width: unset;
  padding: 0 0.5rem;
  margin-bottom: 0;
}

fieldset {
  padding: 1rem;
  border: 1px solid var(--spai-border-color);
  border-radius: 0.25rem;
}

.spai-heatmap-rec-label {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 5px;
  font-family: Nunito;
  font-size: .75rem;
  overflow-y: hidden;
}

.line-height-1 {
  line-height: 1 !important;
  height: auto !important;
}

.spai-variable-panel {
  .mat-dialog-container {
    overflow: visible;
  }

  .mat-dialog-content {
    max-height: 80vh;
    overflow: visible;
  }
}

.ngb-highlight {
  color: var(--spai-danger);
  background-color: var(--spai-warning);
}

.highlighted-match {
  font-weight: bold;

  &.text-green {
    color: var(--spai-success);
  }

  &.text-yellow {
    color: var(--spai-warning);
  }

  &.text-red {
    color: var(--spai-danger);
  }
}

.highlighted-answer {
  background-color: var(--spai-success);
}
