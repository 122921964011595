@import "colors";

.ball {
  transform: rotate(90deg);
  width: 25px;
  height: 25px;
  display: block;
  border-radius: 50%;
  background-color: $green;
  border: 2px solid $green;
  float: left;

  &.red {
    background-color: $red;
    border-color: $red;
  }

  &.yellow {
    background-color: $yellow;
    border-color: $yellow;
  }

  &.green {
    background-color: $green;
    border-color: $green;
  }

  &.zero {
    background-image: linear-gradient(90deg, transparent 50%, black 50%),
    linear-gradient(90deg, black 50%, transparent 50%);
  }

  &.one {
    background-image: linear-gradient(72deg, transparent 50%, black 50%),
    linear-gradient(0deg, black 50%, transparent 50%);
  }

  &.two {
    background-image: linear-gradient(144deg, transparent 50%, black 50%),
    linear-gradient(0deg, black 50%, transparent 50%);
  }

  &.three {
    background-image: linear-gradient(0deg, transparent 50%, $yellow 50%),
    linear-gradient(36deg, black 50%, transparent 50%);
  }

  &.four {
    background-image: linear-gradient(0deg, transparent 50%, $green 50%),
    linear-gradient(108deg, black 50%, transparent 50%);
  }

  &.five {
    background-image: none;
  }
}
