@import 'colors';


// todo: revrite code that use this class
.notification-badge-holder {
  display: inline-block;
  margin-left: 7px;
}

select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}


.container {
  &.main-container {
    padding: 16px;
    max-width: 100%;
  }
}

.title-holder {
  display: flex;

  h2 {
    font-size: 16px;
    color: $white;
  }

  a {
    color: $white;
  }

  .custom-col {
    display: flex;
    align-items: center;
    width: 50%;

    &.col-icon {
      justify-content: flex-end;

      img {
        transition: .3s;
        opacity: .6;

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  svg {
    width: 17px;
  }

  .download {
    padding: 0 10px;

    svg {
      width: 33px;
    }
  }
}

.assets {
  padding: 0;
  margin-top: 10px;

  list-style: none;

  li {
    display: flex;
  }

  .icon {
    margin-right: 15px;

    color: $violet3;
  }
}


.custom-col-0 {
  width: 5%;
}

.custom-col-1 {
  width: 30%;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.flex-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.custom-checkbox {
  position: relative;

  display: block;
  padding-left: 35px;
  margin-bottom: 12px;

  font-size: 22px;
  cursor: pointer;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    font-size: 14px;
    height: 0;
    width: 0;
    cursor: pointer;

    opacity: 0;

    &:checked {
      &~.checkmark {
        background-color: $violet3;

        &:after {
          display: block;
        }
      }
    }
  }

  .checkmark {
    &:after {
      top: 0;
      left: 5px;

      width: 6px;
      height: 14px;

      border: solid $white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
}

.checkmark {
  position: absolute;

  width: 20px;
  height: 20px;

  background-color: transparent;
  border: 2px solid $violet3;
  border-radius: 2px;

  &.explainer-check {
    top: 50%;
    transform: translateY(-50%);
  }

  &:after {
    content: "";
    position: absolute;
    display: none;
  }
}

.badge {
  padding: 4px 10px;
  font-size: 12px;
  text-transform: uppercase;
  border-radius: 5px;

  &.badge-light {
    background-color: white;
    color: #000;
  }
}

.icon {
  transition: .3s;
  opacity: .6;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    opacity: 1;
  }
}

.table {
  color: $white;
}

input[type="date"] {
  padding: .375rem .2rem .375rem .75rem;

  font-size: 14px;
  color: $white;

  background-color: $dark-violet;
  border: none;
  border-radius: 6px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

