body {
  padding: 0;
  margin: 0;
  overflow-y: scroll;
}

.main-screen {

  #closeIntro {
    transition: 0.2 ease-in;

    svg {
      width: 27px;
    }

    &:hover {
      opacity: 0.5;
      transition: 0.2 ease-in;
    }
  }

  .box-wrapper {
    padding: 24px;
    height: 100%;

    background-color: var(--spai-card-bg);
    border-radius: 6px;
    box-shadow: 0 0 9px 0 rgba(0, 0, 0, 0.2);

    &.box-wrapper-lg {
      margin-top: 0;
      margin-right: 0;
      width: 100%;
    }

    &.box-wrapper-md {
      width: 58%;
    }

    &.box-wrapper-sm {
      width: 40%;
    }

    &.metric {
      .body-holder {
        .custom-col {
          text-align: center;

          &:first-child {
            width: 70%;

            border-right: 1px solid rgba(255, 255, 255, 0.4);
          }

          &:last-child {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0 20px;
            margin: 0 auto;
            width: 30%;
          }
        }
      }
    }
  }

  .accordion {
    width: 100%;
  }


  #accordion {
    margin-top: 10px;
    overflow: auto;
    max-height: 300px;
  }

  .profile-picture {
    width: 24px;
    height: 24px;
    object-fit: cover;

    border-radius: 100%;
  }

  .competitor-list {
    padding: 0;
    list-style: none;
  }

  .competitor-item {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    .text-holder {
      p {
        font-size: 14px;
      }
    }

    .image-holder {
      margin-right: 10px;
    }
  }

  .competitor-title {
    margin: 23px 0;

    font-size: 12px;
  }

  .body-holder {
    .row {
      margin: 0;

    }
  }

  .business-icon {
    margin: 0 35px 16px 36px;
    min-width: 24px;
    min-height: 24px;
  }
}

mat-sidenav.spai-sidebar-nav {
  border-right: none;
}

.spai-main-content {
  scrollbar-gutter: stable;
  height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;

  main {
    flex-grow: 1;
  }
}

@media screen and (max-width: 1550px) {
  .main-screen {
    .box-wrapper {
      width: 100%;

      &:not(:nth-child(2n)) {
        margin-right: 0;
      }
    }

    .intro {
      input[type="date"] {
        width: 91%;
        max-width: 146px;
      }

      .custom-col-0 {
        width: 4.5%;
      }

      .custom-col-2 {
        width: 28%;
      }
    }
  }
}

@media screen and (max-width: 1199px) {
  .main-screen {
    .intro:before {
      display: none;
    }
  }
}

@media screen and (max-width: 767px) {
  .main-screen {
    .profile-col {
      width: 100%;
    }

    .list-col {
      display: block;
      overflow-x: auto;
      overflow-y: hidden;
      white-space: nowrap;
      margin-top: 30px;
      width: 100%;

      border-bottom: none;
    }

    .list-item {
      display: inline-block;
      margin-right: 20px;

      &:first-child {
        border-top: none;
      }
    }


    .box-wrapper {
      &.metric {
        .body-holder {
          .custom-col {
            &:first-child {
              width: 100%;
              border-right: none;
            }

            &:last-child {
              width: 100%;
            }
          }
        }
      }
    }

    .business {

      .text-holder,
      .button-holder {
        width: 100%;
      }

      .button-holder {
        margin-top: 20px;
      }
    }

    .business-item {
      width: 100%;
    }
  }
}

@media screen and (max-width: 575px) {
  .main-screen {
    .box-wrapper {
      padding: 24px 15px;
    }

  }
}


#spai-master-container {
  --spai-left-sidebar-width: 276px;
  --spai-master-header-height: 53px;
  --spai-opportunity-page-header-height: 107px;

  &.spai-drawer-transition {
    main {
      transition-duration: 400ms;
      transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
      transition-property: transform, margin-left, margin-right;
    }

    spai-header.spai-master-header,
    .spai-opportunity-header {
      transition-duration: 400ms;
      transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
      transition-property: transform, width;
    }
  }

  &.spai-master-content {
    display: block;
    height: 100vh;
  }

  &.spai-mini-navbar {
    --spai-left-sidebar-width: 53px;
  }

  spai-header.spai-master-header {
    position: fixed;
    z-index: 998;
    width: calc(100% - var(--spai-left-sidebar-width));
    right: 0;
  }

  .spai-main-content {
    min-height: auto;
    overflow: inherit;
    height: 100%;
    min-height: 100vh;

    main {
      display: flex;
      flex: 1;
      flex-basis: 1e-9px;
      flex-direction: column;
    }
  }

  main {
    margin-top: var(--spai-master-header-height);
    margin-left: var(--spai-left-sidebar-width);

    .spai-opportunity {
      display: flex;
      flex: 1;
      flex-basis: 1e-9px;

      .spai-opportunity-page-header {
        display: flex;
        width: 100%;
        height: var(--spai-opportunity-page-header-height);

        .spai-opportunity-header {
          position: fixed;
          top: calc(var(--spai-master-header-height) + 1rem);
          width: calc(100% - (var(--spai-left-sidebar-width) + 32px));
        }
      }
    }

    .spai-opportunity-sidenav-container {
      .spai-opportunity-sidenav {
        right: 16px;
      }
    }
  }

  spai-navbar {
    background-color: var(--spai-card-bg);
    width: var(--spai-left-sidebar-width);
    height: 100%;
    position: fixed;
    left: 0;
  }

  #header.ytd-rich-grid-renderer {
    width: 100%;
    display: flex;
  }
  .spai-deal-picker {
    .mat-form-field {
      &.mat-form-field-appearance-outline {
        .mat-form-field-flex {
          margin-top: auto;
        }

        .mat-form-field-wrapper {
          margin: 0 0;
        }
      }
    }
  }
}
