table:not(.mat-calendar-table),
.table,
mat-table,
.mat-table {
  width: 100%;

  &.table-borderless {
    td {
      padding: 0.25rem;
      // border: none;
    }
  }
}

.spai-mat-table-wrapper {
  overflow: auto;
  height: 100%;
  min-width: 100%;
}

.spai-mat-table,
.spai-table {
  background-color: var(--spai-table-bg);

  tr.mat-header-row {
    height: initial;
    z-index: 99;
  }

  tr {
    height: auto;
  }

  .cdk-header-row,
  thead>tr {
    background-color: var(--spai-table-header-color);
    text-align: left;
    color: var(--spai-text-color);
    position: sticky;
    top: 0;
    z-index: 899;

    .cdk-header-cell,
    th {
      padding: 0.75rem 0.5rem;
    }
  }

  .cdk-header-cell:first-of-type,
  .cdk-cell:first-of-type,
  .cdk-footer-cell:first-of-type {
    padding-left: 1.5rem;
  }

  .cdk-header-cell,
  .cdk-cell,
  .cdk-footer-cell {
    padding: 0.75rem 0.5rem;
    color: var(--spai-table-row-color);
    font-size: 1rem;
    font-weight: 200;
    vertical-align: baseline;
  }

  .cdk-header-cell:last-of-type,
  .cdk-cell:last-of-type,
  .cdk-footer-cell:last-of-type {
    padding-right: 1.5rem;
  }

  .cdk-header-cell {
    color: #ffffff;
    font-weight: 700;
  }

  .cdk-row,
  tbody>tr {
    border-bottom-color: var(--spai-border-color);
    background-color: var(--spai-table-bg);

    &:nth-child(even) {
      box-shadow: inset 0 0 0 9999px var(--spai-table-accent-bg);
      --spai-table-bg: var(--spai-table-accent-bg);
    }
  }

  .spai-mat-header-row {
    border: none;
    background-color: var(--spai-table-header-color);
    text-align: left;
    color: var(--spai-text-color);
    position: sticky;
    top: 0;
  }

  .mat-sort-header-arrow {
    color: #ffffff !important;
  }

  .direction {
    font-size: 19px;
    position: relative;
    top: 2px;
  }
}

.table.table-section.table-striped {

  tbody>tr {
    border-bottom-color: var(--spai-border-color);
    background-color: var(--spai-table-bg);
    color: var(--spai-table-row-color);

    &:nth-child(even) {
      background-color: var(--spai-table-striped-bg);
      box-shadow: none;
    }

    td {
      box-shadow: none;
      color: inherit;
    }
  }
}

.spai-grid-view__empty {
  height: 48px;
  box-shadow: inset 0 0 0 9999px rgba(81, 91, 150, 0.2);
  text-align: center;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

body {
  table:not(.mat-calendar-table) {
    &> :not(caption)>*>* {
      padding: 0.75rem;
      background-color: var(--spai-table-bg);
      border-bottom-width: 1px;
      border-color: rgb(67, 75, 85);
    }

    thead tr {
      background-color: var(--spai-table-header-bg);
    }
  }
}

.spai-table-actions-tools {
  display: flex;
  gap: 1.5rem;
  margin-bottom: 1.5rem;
}
.spai-mat-cell-inner-text {
  display: inline;
  vertical-align: middle;
}
