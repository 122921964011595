.mat-autocomplete-panel,
.mat-mdc-autocomplete-panel {
  border-radius: var(--spai-border-radius) !important;
  // margin-bottom: .25rem!important;;
  margin-top: 0.25rem !important;
}

.spai-mat-panel {
  margin-top: 0.25rem !important;
  border-radius: var(--spai-border-radius) !important;
  border: var(--spai-border) !important;
}

.mat-mdc-autocomplete-panel.mdc-menu-surface {
  border-radius: var(--spai-border-radius);
  border: var(--spai-border);
  margin-bottom: 0.25rem;
  margin-top: 0.25rem;
}

// Defaults for Discovery must be smaller and aligned right like their inputs
.default {
  .mat-option-text {
    font-size: 0.875rem;
    text-align: right;
  }
}

/**
  * for drop down with opportunities on the top
  */
.opportunity-panel {
  .mat-option,
  .mat-mdc-option {
    padding: 0.25rem 1rem;
    height: auto;
    line-height: 1.5rem;
    min-height: 2rem;

    &:hover {
      &:not([disabled]) {
        background-color: var(--spai-dropdown-item-hover-bg);
      }
    }
  }
}

.mat-mdc-option {
  width: 100%;

  .mdc-list-item__primary-text,
  .mdc-list-item--selected {
    color: #ffffff;
    width: 100%;
  }

  &:hover {
    &:not([disabled]) {
      background-color: var(--spai-dropdown-item-hover-bg);
    }
  }

  &.mat-mdc-option-active,
  &.mdc-list-item--selected {
    background-color: rgb(92 107 192 / 75%);
  }
}

.spai-user-autocomplite-trigger {
  display: flex;
  align-items: center;

  .spai-user-autocomplite-trigger--value {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 80%;
    flex-grow: 8 1 auto;
    padding-right: 4px;
  }

  .spai-user-autocomplite-trigger--input {
    flex: 0 1 2%;
    &.focused {
      min-width: 20%;
      flex: 2 1 20%;
    }
  }
}
