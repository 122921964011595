// @import "@carbon/styles/css/styles.css"; // optional stylesheet we don't need for the charts we use
@import "@carbon/charts/styles.css"; // do not use SCSS as it collides with other variables we set

.bx--cc--tooltip {
  border-radius: 0.25rem;
  background-color: var(--#{$prefix}primary);
  font-family: var(--#{$prefix}font-sans-serif);
}

// Donut alterations

.bx--cc--chart-wrapper {
  font-family: var(--#{$prefix}font-sans-serif);
}

// Vertical stacked bar chart

.bx--cc--axes g.axis g.tick text,
.bx--cc--axes g.axis .axis-title {
  font-family: var(--#{$prefix}font-sans-serif);
}

.cds--chart-holder[data-carbon-theme=g90] {
  .chart-grid-backdrop {
    fill: none;
  }
}
