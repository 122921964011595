@import "colors";

.metric-form-modal {
  .modal-xl {
    .modal-content {
      width: 90vw;
    }
  }
}

.edit-pain-questions {
  .modal-body {
    padding: 5px;
  }
}

.new-user,
.new-opportunity {
  .modal-content {
    min-width: 555px;
    padding: 32px;
  }
}

.modal-backdrop.transparent {
  opacity: 0 !important;
}

.modal-content {
  color: $white;
  background-color: $dialog-bg;
}

.modal-title {
  font-size: 16px;
}

.modal-header {
  padding: 0 0 24px;

  border: none;
}

.modal-content {
  padding: 24px;

  border-radius: var(--spai-border-radius, 4px);
}

.modal-body {
  padding: 0 0 24px;
}

.modal-footer {
  padding: 24px 0 0;

  border-top: 1px solid $violet9;
}

.modal {
  &.show {
    .modal-dialog {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }

  .form-group {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &.main-screen {
    padding: 0;

    .modal-content {
      padding: 35px;

      .list-item {
        border-top: 1px solid rgba($white, .3);

        &:last-child {
          border-top: none;
          border-bottom: 1px solid rgba($white, .3);
        }
      }
    }
  }
}

.close-icon {
  transition: 0.3s;
  opacity: 0.6;

  line {
    color: $white;
  }

  &:hover {
    opacity: 1;
  }
}