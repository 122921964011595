$light-focused: rgba(white, 0.12);

$spai-primary: #5C6BC0;
$spai-success: #0acf97;
$spai-warning: #ffbc00;
$spai-dange: #fa5c7c;
$spai-danger: $spai-dange;

// Spotlight dark theme colors
$spai-dark-body-bg: #17171D;
$spai-dark-body-color: white;
$spai-dark-panel-bg: #25283D;
$spai-dark-card-bg: $spai-dark-panel-bg;
$spai-dark-border: #434b55;
$spai-dark-score-bg: #383D5D;
$spai-dark-code-sample-bg: lighten($spai-dark-body-bg, 5%);

// Spotlight light theme colors
$spai-light-body-bg: #fafbfe;
$spai-light-body-color: #6c757d;
$spai-light-card-bg: #ffffff;
$spai-light-border: #e7ebf0;
$spai-light-input-bg: #f1f3fa;

// Bootstrap colors override
$primary: $spai-primary;
$red: #fa5c7c;
$yellow: $spai-warning;
$green: #0acf97;
$orange: #fd7e14;
$secondary: $spai-dark-border;
$dark: $spai-dark-body-bg;
$light: $spai-light-body-bg;

$spotlight-dark-colors: (
  body-bg: #17171D,
  body-color: #ffffff,
  card-bg: #25283D,
  border-coler: #434b55,
  meddic-bg: #111422,
  meddic-border: #2b2f48,
);

$spai-score-collor: (
  'gray':#a9a9a9,
  'gray-light':#d6d6d6,
  'yellow':#fecb56,
  'green': #3cc377,
  'atRisk': #E83F42
);




// legacy
// todo: get rid of this code below

$violet: #2b2f48;
$violet3: #505888;
$violet4: #3d4369;
$violet5: #393e5a;
$violet6: #737dbb;
$violet7: #6f7494;
$violet8: #8c95c9;
$violet9: #7b7f96;
$violet10: #5f6696;
$violet-border: #454a66;
$dark-violet: #141625;
$dark-violet2: #0d0f1b;
$white: #fff;
$white2: #fbfbff;
$opp-select-option: #E6E1E5;
$dirty-white: #c5c8da;
$black: #000;
$light-green: #029477;
$red2:#EB3D3D;
$error-red:#ff6363;
$blue: #3775ff;
$light-blue: #3ca7ff;
$light-blue2: #aedaff;
$havelock-blue: #6491de;
$magenta: #c02dfd;
$light-pink: #ff5e8e;
$ghost: #c4c6d0;
$santas-gray: #a0a1aa;
$gray: #343a40;

$filter-value: #303355;
$dialog-bg: #25283D;
$values: #8E9099;
$even-row-bg: #3d4159;
$dialog-title: #E4E1E6;
$icon: #E5EEFF;
$group-title: #6D7086;
$card-background: #111422;

$meddic-red: #E83F4250;
$meddic-green: #40C07950;
$meddic-yellow: #FECB5650;
