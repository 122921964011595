.answer {
  font-size: 1rem;
}

.form-check-input {
  border: 1px solid var(--#{$prefix}primary);
  background-color: rgba(0,0,0,0);
}

.form-check-input:checked {
  background-color: var(--#{$prefix}primary);
}
