$spai-score-spotlight-diameter: 9.125rem;
$spai-score-major-font-size: 2.875rem;
$spai-score-minor-font-size: 1.5rem;
$spai-score-stroke-width: 0.875rem;
$spai-score-minor-diameter: 5.938rem;

.score-gradients {
  height: 0;
}

.score {
  circle { // only one circle element per score for bg
    stroke-width: $spai-score-stroke-width;
    fill: none;
  }

  text {
    font-weight: 600;
  }
}

.score-major {
  height: $spai-score-spotlight-diameter;
  width: $spai-score-spotlight-diameter;

  path {
    stroke-width: $spai-score-stroke-width;
    fill-opacity: 0;
    // linear-gradients for stroke doesn't work in Safari so use <defs><linearGradient></linearGradient></defs>
  }
  text {
    font-size: $spai-score-major-font-size;
  }
}

.score-minor {
  height: $spai-score-minor-diameter;
  width: $spai-score-minor-diameter;
  text {
    font-size: $spai-score-minor-font-size;
  }
}

.score-discovery-filled, .score-value-filled {
  stroke-width: $spai-score-stroke-width;
  fill-opacity: 0;
}

.score-value-pattern, .score-discovery-pattern {
  stroke-dashoffset: 100;
  fill-opacity: 0;
}

.score-value-pattern {
  stroke-width: $spai-score-stroke-width;
  stroke-dasharray: 3, 10;
}

.score-discovery-pattern {
  stroke-width: $spai-score-stroke-width;
  stroke-dasharray: 3, 3;
}