.accordion {
  --#{$prefix}accordion-active-bg: #{darken($primary, 10%)};
  --#{$prefix}accordion-active-color: white;

  --#{$prefix}accordion-btn-color: white;
  --#{$prefix}accordion-btn-bg: var(--spai-primary-color);
}


spai-pains-questions-list {
  .accordion-button {
    padding-left: 1.5rem;
  }
}
