
// Colors
$primary: #5C6BC0;
$red: #fa5c7c;
$yellow: #ffbc00;
$green: #0acf97;
$orange: #fd7e14;
$blue: #0dcaf0ff;
$secondary: $spai-dark-border;
$dark: $spai-dark-body-bg;
$light: $spai-light-body-bg;

$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $green,
  "warning":    $yellow,
  "danger":     $red
);

 // Component styling not supported by CSS custom variables
$accordion-icon-color: white;
$accordion-icon-active-color: white;
$link-decoration: none;

$table-cell-padding-x: .75rem;
$table-cell-padding-y: .75rem;

$form-switch-color: $primary; // color of switch circle when turned off
$form-file-button-bg: $primary;
$form-file-button-color: white;

$form-select-border-color: var(--spai-border-color);
$form-select-focus-border-color: var(--spai-border-color);
$form-select-indicator-color: white;
$form-select-color: var(--spai-body-color);
$form-select-bg: var(--spai-input-bg);


.mw-50 {
  max-width: 50%!important;
}
