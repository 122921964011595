input {
  border-radius: var(--spai-border-radius);
  background-color: var(--spai-input-bg);
  border: var(--spai-border);
  color: var(--spai-input-color);

  &:focus {
    box-shadow: none;
  }

  &[readonly] {
    background-color: lighten(#25283D, 5%);
    border: var(--spai-border);
    color: var(--spai-input-color);
  }

  &:disabled {
    background-color: lighten(#25283D, 5%);
    border: var(--spai-border);
    color: var(--spai-input-color);
  }
}

.form-control {
  border-radius: var(--spai-border-radius);
  background-color: var(--spai-input-bg);
  border: var(--spai-border);
  color: var(--spai-input-color);

  &:focus {
    background-color: var(--#{$prefix}body-bg);
    color: var(--spai-input-color);
    border-color: var(--spai-border-color);
    box-shadow: rgba(92, 107, 192, 0.25) 0px 0px 0px 0.25rem;
  }

  &[readonly] {
    background-color: var(--spai-input-bg);
    border: var(--spai-border);
    color: var(--spai-input-color);
  }

  &:disabled {
    background-color: lighten(#25283D, 5%);
    border: var(--spai-border);
    color: var(--spai-input-color);
  }

  &-sm {
    --#{$prefix}input-padding-y: 0.25rem;
    --#{$prefix}input-padding-x: 0.5rem;
  }
}

.spai-control-container {

  .input-group:not(.has-validation)> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
  .input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-control,
  .input-group:not(.has-validation)>.form-floating:not(:last-child)>.form-select {
    border-top-right-radius: var(--spai-border-radius) !important;
    border-bottom-right-radius: var(--spai-border-radius) !important;
  }
}

.spai-control-container {
  margin-bottom: 1rem;
  display: block;

  &.spai-metric-field {
    margin-bottom: 0;
  }
}

.spai-metric-field {
  .spai-control-container {
    margin-bottom: 0;
  }
}

.spai-password-input {
  padding-right: 42px;

  &.is-invalid {
    background-image: none;
  }
}

.invalid-feedback {
  text-align: end;
}

.spai-radio-button-group {
  flex: 1;

  .btn-group {
    width: 100%;
  }
}


.spai-radio-button-group {
  position: relative;
  display: inline-flex;
  flex-direction: row;
  white-space: nowrap;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  transform: translateZ(0);
  vertical-align: middle;

  &.spai-radio-button-group-sm {
    .spai-button {
      line-height: 2rem;
      padding: 0 0.5rem;
      font-size: 0.875rem;
    }
  }

  .spai-radio-button:not(:first-child) {
    margin-left: -1px;
  }

  .spai-radio-button:not(:first-child) {
    .spai-button {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }

  .spai-radio-button:not(:last-child) {
    .spai-button {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
  }
}

.btn-group-sm .btn {
  --#{$prefix}btn-padding-y: 0.25rem;
  --#{$prefix}btn-padding-x: 0.5rem;
  --#{$prefix}btn-font-size: 0.875rem;
  --#{$prefix}btn-border-radius: 0.25rem;
}


.spai-question-note-textarea--wrapper {
  display: flex;
  justify-content: flex-end;

  .question-note--textarea {


    scrollbar-width: auto;
    width: 250px;
    resize: both;

    &::-webkit-scrollbar {
      width: 12px;
      border-radius: 25px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--spai-primary);
    }
  }
}
