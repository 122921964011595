/*the container must be positioned relative:*/
.custom-select {
  position: relative;
  // font-family: Arial;
}

.custom-select select {
  display: none;
  /*hide original SELECT element:*/
}

/*style the items (options), including the selected item:*/
.select-items div,
.select-selected {
  color: $dark-violet;
  padding: 6px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
  user-select: none;
}

.select-selected {
  padding: 6px 18px 0 0;
}

/*style items (options):*/
.select-items {
  position: absolute;
  background-color: $white;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

/*hide the items when the select box is closed:*/
.select-hide {
  display: none;
}

.select-items div:hover,
.same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}