.spai-default-info {
  position: absolute;
  z-index: 6;
  top: 50%;
  transform: translateY(-50%);
  left: -0.75rem;
  height: 24px;
  line-height: 1;

  .spai-icon {
    line-height: 1;
    display: block;
  }
}

.mat-option-text {
  line-height: 1.5;
}

.spai-default-info,
.default {
  .mat-option-text::before {
    content: "Default: ";
  }

  &.gpt-answer {
    .mat-option-text::before {
      content: "GPT: ";
    }
  }
}
