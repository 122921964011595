@import './_colors.scss';

ngb-datepicker,
ngb-datepicker-navigation,
ngb-datepicker-month {
  background-color: $dialog-bg;

  .ngb-dp-navigation-select,
  .ngb-dp-header {
    background-color: $dialog-bg;

    .form-select,
    .custom-select {
      background: $dialog-bg;
      color: $white;
    }
  }

  .ngb-dp-content {}

  .ngb-dp-week {
    background: $dialog-bg;
    border-radius: 0;
    color: $dirty-white;

    &.ngb-dp-weekdays {
      border: none;
    }

    .ngb-dp-day {
      background: $dialog-bg;

      .btn-light {
        color: $white;
      }
    }
  }

}

.ngb-dp-month-name {
  background-color: $dialog-bg !important;
  color: $dirty-white;
}