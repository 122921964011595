@import 'colors.scss';

.dropdown-menu {
  background-color: #454E80;
  padding: 0;

  .dropdown-item {
    transition: .2s ease-in;
    color: $white;

    &.disabled {
      opacity: .5;
    }

    &:hover,
    &:focus {
      background-color: $violet5;
    }
  }
}

.transcript-feedback {
  .popover-body {
    padding: 0;
  }
}

.white-pre {
  white-space: pre;
}

.custom-popover {
  background-color: $dialog-bg;
  width: 250px;

  .popover-body {
    color: $white;
    padding: 15px;
  }

  .arrow::after {
    border-left-color: $dialog-bg;
  }

  .arrow {
    left: 25%;

    &::after {
      border-left-color: transparent;
      border-top-color: $violet3;
      background-color: $dialog-bg;

    }
  }
}

// Accordion
.accordion {

  .card {
    height: auto !important;
    min-height: auto !important;

    &.disabled {
      .card-body {
        display: none;
      }
    }

    .card-header {
    }

    .card-body {
      flex: 1;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      padding: 0;
      margin-bottom: 25px;
    }
  }

  &.teams {
   .card {
    .card-body {
      margin-left: 15px;
    }
   }
  }
}

