.mat-calendar-body-cell .mat-calendar-body-cell-content {
  border-radius: var(--spai-border-radius);
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover>.mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: var(--spai-primary-color);
}

mat-datepicker-toggle.sm {
  button.mat-icon-button{
    height: calc(1.5em + 0.5rem + 2px);
    width: calc(1.5em + 0.5rem + 2px);
    line-height: calc(1.5em + 0.5rem + 2px);
  }
}

.question-date-picker {
  &.mat-calendar {
    height: auto !important;
  }

  .mat-calendar-controls {
    margin: 0 !important;
  }
}

