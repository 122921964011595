@use "sass:math";

$spai-meddpicc-btn-diameter: 1.75rem;

.meddpicc {
  height: $spai-meddpicc-btn-diameter;
  width: $spai-meddpicc-btn-diameter;
  border: 1px solid var(--#{$prefix}primary);
  border-radius: math.div($spai-meddpicc-btn-diameter, 2);
  font-size: math.div($spai-meddpicc-btn-diameter, 2);
  background: transparent;
  cursor: pointer;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .4s;

  &.sm {
    height: calc($spai-meddpicc-btn-diameter / 1.5);
    width: calc($spai-meddpicc-btn-diameter / 1.5);
    font-size: math.div($spai-meddpicc-btn-diameter, 2);
  }

  &.md {
    font-size: 1rem;
  }

  &.lg {
    height: $spai-meddpicc-btn-diameter * 1.5;
    width: $spai-meddpicc-btn-diameter * 1.5;
  }

  &.read-only {
    pointer-events: none;
  }

  .meddicc-item-letter {
    display: block;
    margin: 0 auto;
  }
}

.meddpicc-success,
.green {
  background-color: var(--#{$prefix}success);
}

.meddpicc-danger,
.red {
  background-color: var(--#{$prefix}danger);
}

.meddpicc-warning,
.yellow {
  background-color: var(--#{$prefix}warning);
}


.meddicc-group {

  .meddicc-item-letter {
    .letter {
      font-size: 0.875rem;
      vertical-align: middle;
    }
  }

  &.meddicc-group-sm {

    .meddpicc {
      height: 1.4rem;
      width: 1.4rem;
    }
  }
}
