:root {
  --spai-card-header-bg: var(--spai-card-bg);
  --spai-card-header-color: #ffffff;
  --spai-card-color: #ffffff;
  --spai-card-border-color: transparent;

  --spai-card-padding: 1rem;
}

$padding: var(--spai-card-padding) !default;
$border-radius: var(--spai-border-radius) !default;
$card-bg: var(--spai-card-bg);

.card>.card-body {
  padding: 0;
}

mat-card,
.mat-mdc-card,
.spai-card,
.card,
.card-body {
  --mdc-elevated-card-container-color: var(--spai-card-bg);
  border-radius: var(--spai-border-radius, 4px);
  background-color: var(--spai-card-bg);
  padding: $padding;
  border-radius: $border-radius;
  color: var(--spai-card-color);

  .card-title,
  .mat-card-title,
  .spai-card-title,
  h2.card-title,
  h2.spai-card-title {
    color: var(--spai-card-header-color);
    font-size: 1.125rem;
    font-weight: 700;
    font-style: normal;
    line-height: 1.2;
  }

  mat-card-actions.spai-card-actions {
    margin: 0;
  }
}

.mat-card {
  .mat-card-header {
    margin-bottom: 0.5rem;

    .mat-card-title:not(:last-child) {
      margin-bottom: 0.5rem;
    }

    .mat-card-title:last-child {
      margin-bottom: 0;
    }

    .mat-card-subtitle {
      margin-top: 0;

      &:not(:first-child) {
        margin-top: 0;
      }
    }
  }

  .mat-card-header-text {
    display: flex;
    flex-direction: column;
    margin-right: 0;
    margin-left: 0;
    flex-grow: 1;
  }
}

.spai-card-title {
  margin-bottom: 0.5rem;
}


.mat-card-header .spai-card-title {
  margin-bottom: 0;
}
