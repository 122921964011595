.spai-opportunity-sidenav-container {
  flex-grow: 1;
  overflow: inherit;

  .spai-opportunity-sidenav {
    overflow: inherit;

    .mat-drawer-inner-container {
      overflow: inherit;
    }
  }

  .mat-drawer-content {
    position: static;
  }

  .spai-opportunity-sidenav-content {
    overflow: inherit;
  }
}