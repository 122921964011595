// Material expansion panel

.spai-questions {
  .mat-expansion-panel-header {
    &.mat-expansion-toggle-indicator-before {
      .mat-expansion-indicator {
        margin: 0;
        position: absolute;
        left: 5px;
        top: 25%;
      }
    }
  }
}
