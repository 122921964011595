@import "src/styles/config/saas/bootstrap-overrides";

.callout {
  border-width: 1px;
  border-style: solid;
  border-left-width: var(--spai-border-radius);
  border-radius: var(--spai-border-radius);
  padding: 1rem;
  border-color: var(--spai-border-color);

  p:last-child {
    margin-bottom: 0;
  }
}

.callout-warning {
  border-left-color: $yellow;
}

.callout-danger {
  border-left-color: $spai-dange;
}

.callout-success {
  border-left-color: $spai-success;
}

.callout-info {
  border-left-color: $blue;
}
