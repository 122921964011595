.mat-menu-panel,
.mat-mdc-menu-panel {
  margin-top: 2px;
  border: var(--spai-border);
  border-radius: var(--spai-border-radius);
  min-height: 50px !important;
  padding: .5rem 0;

  .mat-menu-item,
  .mat-mdc-menu-item {
    padding: 0.25rem 1rem;
    line-height: 1.5;
    height: auto;
    font-weight: 400;
    min-height: auto;
    border-radius: 0;

    &:hover:not([disabled]) {
      //todo: replace with variable
      background: #5c6bc0;
    }
  }
}

.mat-menu-content:not(:empty) {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.spai-default-menu-panel {
  padding: 0;
  width: 174px;

  .mat-menu-content {
    padding: 0 !important;
  }

  .mat-menu-item {
    height: 48px;
  }
}
