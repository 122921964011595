mat-progress-bar {
  &.mat-progress-bar {
    background-color: var(--spai-panel-color);
    border: 1px solid var(--spai-primary);
    border-radius: var(--spai-border-radius);
    height: 1rem;
    flex: 1 1 100%;
    min-width: 150px;

    .mat-progress-bar-buffer {
      background-color: var(--spai-panel-color);
    }
    .mat-progress-bar-primary.mat-progress-bar-fill.mat-progress-bar-element {
      background-color: white;
    }
  }

  &.red {
    .mat-progress-bar-primary.mat-progress-bar-fill.mat-progress-bar-element {
      background-color: var(--#{$prefix}danger);
    }
  }

  &.yellow {
    .mat-progress-bar-primary.mat-progress-bar-fill.mat-progress-bar-element {
      background-color: var(--#{$prefix}warning);
    }
  }

  &.green {
    .mat-progress-bar-primary.mat-progress-bar-fill.mat-progress-bar-element {
      background-color: var(--#{$prefix}success);
    }
  }

  &.light {
    .mat-progress-bar-primary.mat-progress-bar-fill.mat-progress-bar-element {
      background-color: #A9A9A9;
    }
  }

  &.grey {
    .mat-progress-bar-primary.mat-progress-bar-fill.mat-progress-bar-element {
      background-color: #D6D6D6;
    }
  }

}
