.spai-progress-gauge {
  display: inline-block;
  overflow: hidden;
  line-height: 0;
  position: relative;
  direction: ltr;
  transition: opacity 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1);

  .spai-progress-gauge__determinate-container {
    transform: rotate(-90deg);
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .spai-progress-gauge__determinate-circle {
    fill: rgba(0, 0, 0, 0);
  }

  .spai-progress-gauge__determinate-circle-graphic {
    fill: rgba(0, 0, 0, 0);
  }

  .spai-progress-gauge__determinate-circle-background {
    stroke: $spai-dark-score-bg;
  }

  .spai-progress-gauge__determinate-circle-dash {
    stroke: var(--spai-card-bg);
  }

  .spai-progress-gauge-value-container {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .spai-progress-gauge-value {
    font-weight: 600;
    fill: #fff;
    font-size: 2.875rem;
  }
}

.score-minor {
  .spai-progress-gauge-value {
    font-weight: 600;
    fill: #fff;
    font-size: 1.5rem;
  }
}
