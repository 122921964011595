.spai-accordion-with-table {
  --spai-table-td-first-child-padding-left: 1.5rem;
  --spai-table-td-first-child-padding-right: 1.25rem;

  &.spai-accordion-with-table-sub {
    --spai-table-td-first-child-padding-left: 1.25rem;
    --spai-table-td-first-child-padding-right: 0;
    padding: 1.25rem;
    padding-right: 0;

    .accordion-body {
      --spai-accordion-body-padding-x: 0;
    }
  }

  .accordion-body {
    --spai-accordion-body-padding-y: 0;
    --spai-accordion-body-padding-x: 0;


    table {
      tbody {
        tr {
          td:first-child {
            padding-left: var(--spai-table-td-first-child-padding-left);
          }

          td:last-child {
            padding-right: var(--spai-table-td-first-child-padding-right);
          }

          &:last-child {
            td {
              border-bottom: none;
            }
          }
        }
      }
    }
  }

  .mat-tree-node {
    padding-right: 1.25rem;
    border-bottom: 1px solid rgb(67, 75, 85);

    &:nth-child(even) {
      box-shadow: inset 0 0 0 9999px var(--spai-table-accent-bg);
    }
  }
}
