.popover {
  --#{$prefix}popover-border-radius: var(--#{$prefix}border-radius);
  --#{$prefix}accordion-inner-border-radius: var(--#{$prefix}body-font-family);
  --#{$prefix}popover-max-width: 25%;
  font-family: var(--#{$prefix}body-font-family);
}

ngb-popover-window {
  border-radius: var(--#{$prefix}border-radius) !important;
}

.popover-header {
  --#{$prefix}popover-inner-border-radius: var(--#{$prefix}border-radius);
}