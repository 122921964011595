
.main-screen .main-nav-tabs{
  flex-wrap: nowrap;
}
.main-nav-tabs {
  &.disabled {
    .nav-item {
      pointer-events: none;

      &.menu4 {
        pointer-events: initial;
      }
    }
  }

  .nav-item {
    &:not(:last-child){
      margin-right: -0.65%;
    }
    width: 17.2%;
    min-height: 32px;

    border: 1px solid $dark-violet;

    clip-path: polygon(95% 1%, 100% 50%, 95% 100%, 0% 100%, 5% 50%, 0% 0%);

    &:first-child {
      clip-path: polygon(95% 1%, 100% 50%, 95% 100%, 0% 100%, 0% 50%, 0% 0%);
    }

    &:last-child {
      clip-path: polygon(100% 1%, 100% 50%, 100% 100%, 0% 100%, 5% 50%, 0% 0%);
    }
  }

  .nav-link {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1px 23px;
    height: 100%;

    font-size: 11px;
    color: $violet6;
    text-align: center;

    border: none;
    background-color: $violet;
    border-radius: 4px;
    transition: 0.2s ease-in;

    &.active-nav {
      color: $violet3;

      background-color: $white;
      border: none;

      &:hover,
      &:focus,
      &:active {
        color: $violet3;

        background-color: $white;
        border: none;
      }
    }

    &.nav-link-next {
      color: $white;

      background-color: $violet3;
      border: none;
    }
  }

  svg {
    width: 16px;
  }
}

// .nav-tabs {
//   .nav-link {

//     &:hover,
//     &:active,
//     &:focus {
//       color: $white;

//       background-color: $violet3;
//       border: none;
//       transition: 0.2s ease-in;
//     }
//   }
// }

.tab-number {
  display: inline;

  font-family: Roboto-Bold;
  font-size: 14px;
  font-weight: 800;
}

.tab-name {
  margin-left: 5px;

  font-style: italic;
}

.tab-current {
	text-decoration: underline;
}

.tab-content {
  &.step-4 {
    //padding: 0 15px;
    overflow: auto;
    height: calc(100vh - 130px);
	-ms-overflow-style: none;  /* IE and Edge */
  	scrollbar-width: none;  /* Firefox */
  }
  &.step-4::-webkit-scrollbar {
	display: none; /* Hide scrollbar for Chrome, Safari and Opera */
  }
}
