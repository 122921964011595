.custom-nav {
  padding-bottom: 24px;
  display: flex;
  align-items: center;

  &:hover {
    .tab-number {
      border-color: $blue;

      span {
        color: $blue;
      }
    }
  }

  &.active {
    background-color: transparent;
    border: none;

    .tab-number {
      background-color: $blue;
      border-color: $blue;

      span {
        color: #ffffff;
      }
    }
  }

  &.disabled {
    .tab-number {
      color: $violet9;
    }

    .tab-name {
      color: $violet9;
    }
  }

  .tab-number {
    border: 2px solid #7b7f96;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;

    span {
      font-size: 24px;
      color: #7b7f96;
    }
  }

  .tab-name {
    margin-left: 0.5rem;
    color: #ffffff;
    font-size: 1rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    max-width: 160px;
  }
}
