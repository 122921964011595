// Small label above field
.mat-form-field-label {
    color: white;
  }
  
  // When field has focus..
  .mat-focused {
    // Don't change field label color
    .mat-form-field-label {
      color: white;
    }
  
    // Set background to match Bootstrap form-control behavior
    .mat-form-field-outline {
      background-color: var(--spai-body-bg);
      border-top-width: 1px;
    }
  }
  
  // Border and background color
  .mat-form-field-outline {
    color: var(--spai-border-color)!important;
    background-color: var(--spai-card-bg);
  }
  
  .mat-form-field-ripple {
    background-color: var( --spai-primary-color)!important;
  }