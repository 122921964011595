:root{
  --#{$prefix}nav-tabs-link-hover-border-color: var(--spai-primary);
}

.nav-link {
  --#{$prefix}nav-link-color: #{$gray-500};

  svg {
    margin-right: 0.625rem;
  }

  svg.icon-expanded {
    transform: rotate(90deg);
  }

}

.nav-child {
  margin-left: 1.5rem;
}

.bg-nav {
  background-color: var(--spai-nav-bg);
  padding: 1rem !important;
}
