@import "colors";

.mat-checkbox {
  .mat-checkbox-label {
    color: var(--spai-label-color);
  }

  .mat-checkbox-frame {
    border-color: #ffffffb3;
  }

  .mat-checkbox-layout {
    margin-bottom: 0;
  }
}