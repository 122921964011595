.spai-close-stage-popup {
  margin-top: 10px;
  border: var(--spai-border);

  .card-header {
    padding: 0;
    padding-bottom: 0.5rem;

    .spai-button-icon {
      line-height: 1;
      height: auto;
      float: right;
      width: 16px;
    }
  }

  .card-body {
    .spai-button{
      width: 8rem;
    }
  }
}
