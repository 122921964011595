.mat-datepicker-toggle {
  display: inline-block;
  height: 100%;
  margin-left: -12px;

  .mat-datepicker-toggle-default-icon {
    width: 1rem;
    height: 1rem;
    color: #ffffff !important;
  }
  button.mat-mdc-button-base {
    width: 40px;
    height: 36px;
    padding: 8px 0;
  }
}
