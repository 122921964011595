.mat-mdc-standard-chip {
  --mdc-chip-elevated-container-color: #{$primary};
}

.mat-chip {
  --spai-mat-chips-bg: #{$primary};
  --spai-mat-chips-hover-bg: #4e5ba3;

  position: relative;

  &.spai-enum-chip {
    min-height: 32px;
    height: 1px;
    max-width: 100%;
    display: inline-flex;
    align-items: center;
    line-height: 1;
    border-radius: 16px;
    padding: 7px 8px 7px 12px;

    .spai-enum-label {
      line-height: 1;
      display: inline-block;
      vertical-align: middle;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .mat-chip-remove {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      width: 18px;
      height: 18px;
      font-size: 18px;
    }

    .mat-chip-trailing-icon {
      margin-left: 8px;
    }
  }

  &.mat-standard-chip {
    background-color: var(--spai-mat-chips-bg);

    &:hover {
      background-color: var(--spai-mat-chips-hover-bg);
    }

    &:after {
      background-color: transparent;
    }

  }

  .mat-chip-remove {
    line-height: 1;
    vertical-align: middle;
    padding: 0;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      opacity: 0.8 !important;
    }
  }
}

.mat-chip {
  font-weight: 400;
  font-size: .875rem;
}


.mat-standard-chip .mat-chip-remove.mat-icon,
.mat-standard-chip .mat-chip-remove .mat-icon {
  height: unset;
  width: unset;
}

.mat-chip-remove.material-icons {
  height: unset;
  width: unset;
  position: unset;
  top: unset;
}

.spai-pipeline-chips-container {

  .mat-chip-list-wrapper input.mat-input-element,
  .mat-chip-list-wrapper .mat-standard-chip {
    margin: 4px;
  }

  .mat-standard-chip.mat-chip-with-trailing-icon {
    padding: 7px 8px 7px 12px;
  }

  .drag-chip[_ngcontent-xbo-c131] {
    border: solid 1px rgba(0, 0, 0, .12);
    background-color: #fff;
  }

  .mat-chip.mat-standard-chip {
    background-color: rgb(92, 107, 192);
    color: rgb(255, 255, 255);
  }

  .mat-chip.mat-standard-chip {
    background-color: rgb(97, 97, 97);
    color: rgb(255, 255, 255);
  }

  .mat-standard-chip {
    transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
    display: inline-flex;
    padding: 7px 12px;
    border-radius: 16px;
    align-items: center;
    cursor: default;
    min-height: 32px;
    height: 1px;
  }

  .mat-chip {
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  .drag-chip {
    background-color: rgb(92, 107, 192) !important;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.12);
    border-image: initial;
  }

  .mat-standard-chip .mat-chip-remove.mat-icon,
  .mat-standard-chip .mat-chip-remove .mat-icon {
    height: unset;
    width: unset;
  }

  .mat-chip-remove.material-icons {
    height: unset;
    width: unset;
    position: unset;
    top: unset;
  }

  .mat-chip.mat-standard-chip .mat-chip-remove {
    svg {
      margin-left: 0;
    }
  }

  mat-icon {
    height: unset;
    width: unset;
    font-size: .875rem;
  }

  .mat-chip {
    font-weight: 400;
    font-size: .875rem;
  }
}



.spai-chips {
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  display: inline-flex;

  padding-top: 7px;
  padding-bottom: 7px;
  padding-right: 8px;
  padding-left: 12px;

  border-radius: 16px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
  align-items: center;
  cursor: default;
  min-height: 32px;
  height: 1px;
  overflow: hidden;
}
