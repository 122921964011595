.score {
  circle { // only one circle element per score for bg
    stroke: $spai-dark-score-bg;
  }
  text {
    fill: #fff;
  }
}

.score-value-pattern, .score-discovery-pattern {
  stroke: var( --#{$prefix}card-bg);
}
