$spai-stage-done: darken($success, 10%);
$spai-stage-done-hover: darken($spai-stage-done, 10%);

$spai-stage-blocked: darken($danger, 10%);
$spai-stage-blocked-hover: darken($spai-stage-blocked, 20%);

$spai-stage-current: $primary;
$spai-stage-current-hover: darken($spai-stage-current, 10%);

$spai-stage-incomplete-bg: $secondary;
$spai-stage-incomplete-hover-bg: darken($secondary, 10%);

:root {
  --spai-stage-arrow-width: 1.063rem;
  --spai-stage-distance: 0.376rem;
  --spai-stage-clip-path: polygon(calc(100% - var(--spai-stage-arrow-width)) 0%,
      100% 50%,
      calc(100% - var(--spai-stage-arrow-width)) 100%,
      0% 100%,
      var(--spai-stage-arrow-width) 50%,
      0% 0%);
  --spai-stage-first-clip-path: polygon(calc(100% - var(--spai-stage-arrow-width)) 0%,
      100% 50%,
      calc(100% - var(--spai-stage-arrow-width)) 100%,
      0% 100%,
      0% 50%,
      0% 0%);
  --spai-stage-last-clip-path: polygon(100% 0%, 100% 50%, 100% 100%, 0% 100%, var(--spai-stage-arrow-width) 50%, 0% 0%);

  --spai-stage-arrow-width: 1.063rem;
  --spai-stage-distance: 0.376rem;
  --spai-stage-padding-x: var(--spai-stage-arrow-width) + (var(--spai-stage-distance) / 2);
  --spai-stage-content-margin-x: 20px;

  --spai-stage-blocked: #{$spai-stage-blocked};
  --spai-stage-blocked-hover: #{$spai-stage-blocked-hover};
  --spai-stage-done: #{$spai-stage-done};
  --spai-stage-done-hover: #{$spai-stage-done-hover};
  --spai-stage-current: #{$spai-stage-current};
  --spai-stage-current-hover: #{$spai-stage-current-hover};
  --spai-stage-incomplete-hover: #{$spai-stage-incomplete-hover-bg};
  --spai-stage-border-left: 1.063rem;
  --spai-stage-font-size: 0.875rem;
  --spai-stage-padding: 0.625rem;
}

.spai-stages-wrapper {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  overflow: auto;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;

  /* Firefox */
  &::-webkit-scrollbar {
    display: none;
    /* Hide scrollbar for Chrome, Safari and Opera */
  }
}


.spai-stage {
  background-color: $spai-stage-incomplete-bg;
  color: white;
  cursor: pointer;
  font-size: var(--spai-stage-font-size);
  min-width: var(--spai-stage-min-width);
  position: relative;
  text-align: center;
  -webkit-clip-path: var(--spai-stage-clip-path);
  clip-path: var(--spai-stage-clip-path);
  width: 100%;
  border: none;
  padding: 0 var(--spai-stage-padding-x);

  &:hover,
  &:active,
  &:focus {
    background-color: var(--spai-stage-incomplete-hover);
  }

  &-done {
    background-color: var(--spai-stage-done);

    &:hover,
    &:active,
    &:focus {
      background-color: var(--spai-stage-done-hover);
    }
  }

  &-blocked {
    background-color: var(--spai-stage-blocked);

    &:hover,
    &:active,
    &:focus {
      background-color: var(--spai-stage-blocked-hover);
    }
  }

  &-current {
    color: #fff;
    background-color: var(--spai-stage-current);

    &:hover,
    &:active,
    &:focus {
      background-color: var(--spai-stage-current-hover);
    }
  }

  .spai-stage-content {
    display: flex;
    justify-content: center;
    color: #ffffff;
    text-decoration: none;
    padding: var(--spai-stage-padding);
    margin: 0 var(--spai-stage-content-margin-x);
  }

  .spai-stage-name {
    text-overflow: ellipsis;
    flex-wrap: nowrap;
    white-space: nowrap;
    font-weight: 600;
  }
}
