@import "colors";

.tooltip {
  &.in {
    opacity: 1 !important;
    filter: alpha(opacity=100) !important;
  }

  &.show {
    opacity: 1 !important;
  }

  &.bs-tooltip-right {
    .arrow:before {
      border-right-color: $violet !important;
    }
  }

  &.bs-tooltip-left {
    .arrow:before {
      border-left-color: $violet !important;
    }
  }

  &.bs-tooltip-bottom {
    .arrow:before {
      border-bottom-color: $violet !important;
    }
  }

  &.bs-tooltip-top {
    .arrow:before {
      border-top-color: $violet !important;
    }
  }

  .tooltip-inner {
    padding: 16px;
    max-width: 176px;

    font-size: 14px;
    line-height: 1.5;
    color: $white;
    text-align: left;

    background-color: #2b2f48 !important;
    border-radius:  var(--spai-border-radius, 4px);
    opacity: 1.0 !important;
    filter: alpha(opacity=100) !important;
  }

  span {
    font-size: 12px;
    color: $dirty-white;
  }
}

.spai-mat-tooltip {
  border: var(--spai-border);
  border-radius: var(--spai-border-radius, 4px);

  .mdc-tooltip__surface {
    max-width: 450px;
    background-color: var(--spai-card-bg) !important;
    color: var(--spai-card-color) !important;

  }
}
