@import "colors.scss";

.angular-editor {
  .select-font {
    display: none !important;
  }

  .angular-editor-toolbar {
    background: transparent !important;
    border: none !important;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    padding: 0;
    
    .angular-editor-toolbar-set{
      margin: 0;
    }
    
    .ae-picker-options {
      button {
        border-radius: 0 !important;
      }
    }

    button {
      background-color: $violet4 !important;
      font-size: 12px;
      color: $dirty-white;
      transition: 0.3s;
      border-radius: 6px;

      &:hover {
        background-color: $violet3 !important;
      }

      &.active {
        background-color: $white !important;
        color: $violet3 !important;
      }
    }
  }

  .angular-editor-wrapper {
    .angular-editor-textarea {
      transition: .3s;
      font-size: 14px !important;
      color: $dirty-white !important;
      background-color: $dark-violet !important;
      border-radius: 6px !important;
      border-color: $dark-violet !important;

      &.focused {
        border: 1px solid $white !important;
      }
    }
  }
}

.angular-editor-form-control {
  &.ng-touched.ng-invalid {
    .angular-editor-textarea {
      border-color: $error-red !important;
    }

    + .error-text {
      visibility: visible;
      opacity: 1;
    }
  }
}
