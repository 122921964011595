
.mat-tab-group {

  &.administration {

    .mat-tab-label {
      min-width: auto;
      height: auto;
      margin-bottom: .5rem;
    }
  }
}

.mat-tab-header-pagination {
  &.mat-tab-header-pagination-disabled {
    opacity: .5;
  }
}

.mat-tab-header-pagination-chevron {
  border-color: var(--spai-link-color);
}
