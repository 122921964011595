// .mat-dialog-content {
//     box-shadow: 0 11px 15px -7px #0003, 0 24px 38px 3px #00000024, 0 9px 46px 8px #0000001f;
// }

.dialog-content-inner {
  display: flex;
  gap: 1rem;
  height: calc(65vh - 3.75rem);

  .list-container {
    padding: 0;
    flex: 1 1 49%;
    height: 100%;
    align-content: center;

    label {
      padding-bottom: 0.25rem;
      border-bottom: var(--spai-border);
      width: 100%;
    }
  }

  .list-wrapper {
    height: calc(100% - 1.5rem);
    overflow: auto;

  }

  .list {
    padding: 0;
    list-style: none;
    margin: 0;
    height: 100%;

    &.cdk-drop-list-disabled {
      .list-item.cdk-drag {
        cursor: default!important;
      }
    }

    .list-item {
      padding: 5px;
      display: flex;
      justify-content: space-between;

      &:not(:first-child) {
        border-top: var(--spai-border);
      }
    }
  }
}
