@import "colors";


.spai-grid-matrix-container {
  mat-header-row,
  mat-footer-row,
  mat-row {
    min-width: 320px;
    /* 24 columns, 80px each */
    border-style: none !important;
  }

  .mat-header-cell,
  .mat-footer-cell,
  .mat-cell {
    min-width: 120px;
    box-sizing: border-box;
  }

  .mat-cell {
    min-height: 70px;
    padding: 10px;
    border-bottom: 1px solid $violet-border;
  }
}


spai-grid-matrix-view {
  .mat-table {
    background-color: var(--spai-card-bg) !important;
  }

  mat-row .mat-cell{
    display: flex;
    align-content: center;
  }
}

.mat-table {
  background-color: transparent;

}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: var(--spai-border-radius, 4px);
  box-shadow: 0 5px 5px -3px #0000001E, 0 8px 10px 1px #00000023, 0 3px 14px 2px #0000001E;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
