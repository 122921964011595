@use "@angular/material" as mat;

$spotlight-typography: mat.define-legacy-typography-config($font-family: $spotlight-font-family-sans-serif,

    $display-2: mat.define-typography-level(1.125rem, 1.35rem, 700),
    $display-1: mat.define-typography-level(1.5rem, 1.8rem, 700),

    $body-2: mat.define-typography-level(1rem, 1.5rem, 700),
    $body-1: mat.define-typography-level(1rem, 1.5rem, 200),
  );

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($spotlight-typography);`
@include mat.all-legacy-component-typographies($spotlight-typography);
@include mat.legacy-core(); // can only be called once

@include mat.core();

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6,

.display-3,
.display-5 {
  margin: 10px 0;
  font-weight: bold;
}

.display-3 {
  font-size: 1.5rem;
  font-weight: 700;
}


.display-5 {
  font-size: .9375rem;
  font-weight: 700;
}

.display-6 {
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.2;
}


small {
  font-size: .75rem !important;
}
