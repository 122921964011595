.meddpicc {
  color: white;
}

.meddpicc-success,
.meddpicc-warning,
.meddpicc-danger,
.green,
.yellow,
.red {
  color: $dark;
}

.meddpicc:hover {
  border-color: white;
}

.meddpicc-success:hover,
.meddpicc-warning:hover,
.meddpicc-danger:hover {
  color: white;
}

.meddpicc-success:hover,
.green:hover {
  background-color: darken($success, 10%);
}

.meddpicc-warning:hover,
.yellow:hover {
  background-color: darken($warning, 10%);
}

.meddpicc-danger:hover,
.red:hover {
  background-color: darken($danger, 10%);
}
